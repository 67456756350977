import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-groupinfo",
  templateUrl: "./groupinfo.component.html",
  styleUrls: ["./groupinfo.component.scss"],
})
export class GroupinfoComponent implements OnInit {
  sellData = [];
  pageNo: any = 1;
  search: any;
  totalItem: any;
  baseUrl = environment.imageUrl;
  isSellSuccess: boolean = false;
  tabType = 1;
  searchForm: FormGroup;
  length = 10;
  room_id: any;
  group_name: any;
  groupInfo: any;
  constructor(
    public router: Router,
    private http: HttpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.room_id = this.route.snapshot.params["id"];
    this.group_name = this.route.snapshot.params["type"];
    console.log(this.room_id);

    this.getSellWebPaginationList(this.pageNo * this.length, this.room_id);
  }

  getSellWebPaginationList(pageNo: any, room_id: any) {
    this.isSellSuccess = false;
    this.sellData = [];
    let StartPage = pageNo - this.length;
    var param = {
      draw: 2,
      columns: [
        {
          data: "created_by__first_name",
          name: "",
          searchable: true,
          orderable: true,
        },
        {
          data: "created_at",
          name: "",
          searchable: true,
          orderable: true,
        },
      ],
      order: [
        {
          column: 1,
          dir: "undefined",
        },
      ],
      start: StartPage,
      length: this.length,

      room_id: Number(this.room_id),
    };
    this.http
      .httpPostWithHeaderAndBody("getGroupInfo", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.sellData = res["data"];
          this.groupInfo = res.group_details.group_details;
          console.log(this.sellData);

          this.totalItem = res.recordsTotal;
          this.isSellSuccess = true;
        } else if (res.code == 204) {
          this.sellData = [];
          this.isSellSuccess = true;
        }
      });
  }
}
