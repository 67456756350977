import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-edit-lead',
  templateUrl: './edit-lead.component.html',
  styleUrls: ['./edit-lead.component.scss']
})
export class EditLeadComponent implements OnInit {
  today: Date;
  routeId: any;
  type: any
  userId: string;
  arr = [];
  editUserDetailsForm : FormGroup;
  allSectorList:any[] = [];
  formattedaddress = " ";
  longitude: any;
  latitude: any;
  fileData: File;
  imageUrl: string = "";
  coverFileData:File;
  coverImageUrl : string = "";
  submitted : boolean = false;
  fromDate: string = "";
  todayDate: string;
  newsFeed:any;
  adsData = [];
  pageNo: any = 1;
  search: FormControl = new FormControl("");
  totalItem: any;
  isadsSuccess:boolean = false;
  educationalDetails: any[] =[];
  education_id: string;
  workDetails: any[] = [];
  work_id: string;
  followers:string;
  following: string;
  leadId: string;
  getborrowerList: any;
  getCollateralList: any;
  getStateList: any;
  borroweId: any;
  lenderId: any;

  constructor(private route: ActivatedRoute, private http:HttpService, private fb:FormBuilder,private router:Router, private commonService: CommonService) {
    this.today = new Date();
    this.type = this.route.snapshot.paramMap.get('type');
    this.leadId = this.route.snapshot.paramMap.get('id');
      
    this.editUserDetailsForm = this.fb.group({
      "borrower" : new FormControl('',Validators.required),
      "street_address": new FormControl('', Validators.required),
      "city" : new FormControl('',Validators.required),
      "county" : new FormControl('',Validators.required),
      "state" : new FormControl('',Validators.required),
      "loan_amount" : new FormControl('',Validators.required),
      "lender" : new FormControl('',Validators.required),
      "origination_date" : new FormControl('',Validators.required),
      "maturity_date" : new FormControl('',Validators.required),
      "collateral_type" : new FormControl('',Validators.required),
    });   
  }

  ngOnInit() {
    this.todayDate = moment(this.today).format("YYYY-MM-DD");
    this.getProfileDetails(); 
    this.getDropDownData();
  
  }





  

  getProfileDetails()
  { 
    this.http.httpGetWithHeaderAndId("singleLeadDetails",this.leadId).subscribe((res:any)=>{
      if(res.code == 200)
      {
        console.log(res.data)
        this.editUserDetailsForm.patchValue(res.data)
        this.borroweId= res.data.borrower.id
        this.lenderId = res.data.lender.id

        this.editUserDetailsForm.patchValue({
          "borrower" : res.data.borrower.borrower,
           "state" : res.data.state.id,
          "lender" : res.data.lender.lender,
          "collateral_type" : res.data.collateral_type.id,
         
        })
        this.editUserDetailsForm.controls['origination_date'].patchValue(res['data']['origination_date'].split('T')[0])
        this.editUserDetailsForm.controls['maturity_date'].patchValue(res['data']['maturity_date'].split('T')[0])  
      }
      
    })
  }


  onInputChange(event) {
    var locationName: any = "";
    locationName = this.editUserDetailsForm.value.address;
    if (locationName != this.formattedaddress) {
      this.editUserDetailsForm.controls["street_address"].reset();
    }
  }

  AddressChange(address: any) {
    console.log(address);
    address.address_components.forEach((res) => {
      console.log(res);
      if (
        res.types.includes("locality") ||
        res.types.includes("postal_town") ||
        res.types.includes("sublocality") ||
        res.types.includes("sublocality_level_1") ||
        res.types.includes("sublocality_level_2") ||
        res.types.includes("sublocality_level_3") ||
        res.types.includes("sublocality_level_4") ||
        res.types.includes("sublocality_level_5")
      ) {
        this.formattedaddress = res.long_name;
      }
    });
    this.longitude = address.geometry.location.lng();
    this.latitude = address.geometry.location.lat();
    this.editUserDetailsForm.controls["street_address"].patchValue(this.formattedaddress);
    this.editUserDetailsForm.controls["city"].patchValue(this.formattedaddress);
    this.editUserDetailsForm.controls["state"].patchValue(address.address_components[3].long_name);
    console.log(this.formattedaddress);
  } 

  getDropDownData() {
    const getLoanCollateralTypeList = this.http.httpGetWithHeaderOnly(
      "getColletralApi"
    );
    const getAllState = this.http.httpGetWithHeaderOnly("getAllState");
    forkJoin([
      getLoanCollateralTypeList,
      getAllState
    ]).subscribe((results: any) => {
      console.log(results);
     
      if (results[0].code == 200) {
        this.getCollateralList = results[0].data;
      }
      if (results[1].code == 200) {
        this.getStateList = results[1].data;
      }
    });
  }
  updateUserDetails() {
    this.submitted = true;
  
    if (this.submitted && this.editUserDetailsForm.valid) {
      let data = this.editUserDetailsForm.value;
      const formattedDate = new Date(data.maturity_date + 'T00:00:00+00:00');
      data.maturity_date = formattedDate.toISOString();
      const formattedDate1 = new Date(data.origination_date + 'T00:00:00+00:00');
      data.origination_date = formattedDate1.toISOString();
  
      // Restructure borrower property
      data.borrower = {
        "id": this.borroweId,
        "borrower": this.editUserDetailsForm.value.borrower
      };
  
      // Restructure lender property
      data.lender = {
        "id": this.lenderId,
        "lender": this.editUserDetailsForm.value.lender
      };
  
      console.log(data, 'ddd');
  
      this.http.httpPutWithFormDataHeaderAndBodyWithid("updateLeadById", this.leadId, data).subscribe((res: any) => {
        if (res.code == 200) {
          this.commonService.presentsToast("success", "Updated Successfully")
          this.router.navigateByUrl('/superadmin/real-state-lead')
        }
      });
    }
  }
}
