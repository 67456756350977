import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Console } from "console";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-participants",
  templateUrl: "./participants.component.html",
  styleUrls: ["./participants.component.scss"],
})
export class ParticipantsComponent implements OnInit {
  sellData = [];
  buyData = [];
  pageNo: any = 1;
  search: any;
  totalBuyItem: number = 1;
  baseUrl = environment.imageUrl;
  isSellSuccess: boolean = false;
  isBuySuccess: boolean = false;
  tabType = 1;
  searchForm: FormGroup;
  length = 10;
  totalSellItem: number = 1;

  constructor(
    public router: Router,
    private http: HttpService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.searchForm = this.fb.group({
      search: [""],
    });
  }

  ngOnInit() {
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
    this.getBuyWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );

    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.tabType = params.type;
      }
    });
  }

  onTabChange(type) {
    this.pageNo = 1;
    this.tabType = type;
    this.router.navigate(["superadmin/participants"], {
      queryParams: { type: this.tabType },
    });
    if (this.tabType == 1) {
      this.getSellWebPaginationList(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    } else {
      this.getBuyWebPaginationList(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    }
  }

  getSellWebPaginationList(pageNo: any, search: any) {
    this.isSellSuccess = false;
    this.sellData = [];
    let StartPage = pageNo - this.length;
    var param = {
      draw: 2,
      columns: [
        {
          data: "created_by__first_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "created_at",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 1,
          dir: "undefined",
        },
      ],
      start: StartPage,
      length: this.length,
      search: {
        value: this.searchForm.get("search").value,
        regex: false,
      },
    };
    this.http
      .httpPostWithHeaderAndBodyWithId("getWebPaginationList", 1, param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.sellData = res["data"];
          this.totalSellItem = res.recordsTotal;
          this.isSellSuccess = true;
        } else if (res.code == 204) {
          this.sellData = [];
          this.isSellSuccess = true;
        }
      });
  }

  getBuyWebPaginationList(pageNo: any, search: any) {
    this.isBuySuccess = false;
    this.buyData = [];
    let StartPage = pageNo - this.length;
    var param = {
      draw: 2,
      columns: [
        {
          data: "created_by__first_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "created_at",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 1,
          dir: "undefined",
        },
      ],
      start: StartPage,
      length: this.length,
      search: {
        value: this.searchForm.get("search").value,
        regex: false,
      },
    };
    this.http
      .httpPostWithHeaderAndBodyWithId("getWebPaginationList", 2, param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.buyData = res["data"];
          this.totalBuyItem = res.recordsTotal;
          this.isBuySuccess = true;
        } else if (res.code == 204) {
          this.buyData = [];
          this.isBuySuccess = true;
        }
      });
  }

  getPage(tab, pageNo: number) {
    this.pageNo = pageNo;
    if (tab == 1) {
      this.getSellWebPaginationList(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    } else {
      this.getBuyWebPaginationList(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    }
  }

  submitSearch(tab) {
    if (this.searchForm.get("search").value !== "") {
      this.pageNo = 1;
      if (tab == 1) {
        this.getSellWebPaginationList(
          this.pageNo * this.length,
          this.searchForm.get("search").value
        );
      } else {
        this.getBuyWebPaginationList(
          this.pageNo * this.length,
          this.searchForm.get("search").value
        );
      }
    }
  }

  onKeyUp(event, tab) {
    this.search = event;
    this.pageNo = 1;
    if (tab == 1) {
      this.getSellWebPaginationList(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    } else {
      this.getBuyWebPaginationList(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    }
  }

  deleteJob(type, id) {
    this.tabType = type;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http
          .httpDeleteHeader("deleteParticipants", id)
          .subscribe((res: any) => {
            Swal.fire("Deleted!", "Paticipant has been deleted.", "success");
            if (type == 1) {
              this.getSellWebPaginationList(
                this.pageNo * this.length,
                this.searchForm.get("search").value
              );
            } else {
              this.getBuyWebPaginationList(
                this.pageNo * this.length,
                this.searchForm.get("search").value
              );
            }
          });
      }
    });
  }
}
