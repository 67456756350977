import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { MaterialModule } from "./material/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DropDownButtonAllModule } from "@syncfusion/ej2-angular-splitbuttons";
import { TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import {
  DropDownListAllModule,
  MultiSelectAllModule,
} from "@syncfusion/ej2-angular-dropdowns";
import {
  MaskedTextBoxModule,
  UploaderAllModule,
} from "@syncfusion/ej2-angular-inputs";
import {
  ToolbarAllModule,
  ContextMenuAllModule,
} from "@syncfusion/ej2-angular-navigations";
import {
  ButtonAllModule,
  CheckBoxAllModule,
  SwitchAllModule,
} from "@syncfusion/ej2-angular-buttons";
import {
  DatePickerAllModule,
  TimePickerAllModule,
  DateTimePickerAllModule,
} from "@syncfusion/ej2-angular-calendars";
import {
  NumericTextBoxAllModule,
  TextBoxAllModule,
} from "@syncfusion/ej2-angular-inputs";
import {
  ScheduleAllModule,
  RecurrenceEditorAllModule,
} from "@syncfusion/ej2-angular-schedule";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthModule } from "./auth/auth.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { apiList } from "./services/api-list";
import { HttpService } from "./services/http.service";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { InterceptorService } from "./services/interceptor.service";
import { LocalStorageService } from "angular-web-storage";
import { SuperadminModule } from "./superadmin/superadmin.module";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxEchartsModule } from 'ngx-echarts';
import { CKEditorModule } from 'ckeditor4-angular';
import { MatFormFieldModule } from "@angular/material";
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    NumericTextBoxAllModule,
    TextBoxAllModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    CheckBoxAllModule,
    ToolbarAllModule,
    DropDownListAllModule,
    ContextMenuAllModule,
    MaskedTextBoxModule,
    UploaderAllModule,
    MultiSelectAllModule,
    TreeViewModule,
    ButtonAllModule,
    DropDownButtonAllModule,
    SwitchAllModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    NgxUiLoaderModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    CKEditorModule,
    AuthModule,
    SuperadminModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxEchartsModule,
    MatFormFieldModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    NgxUiLoaderService,
    apiList,
    LocalStorageService,
    HttpService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
