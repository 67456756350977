import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";

@Component({
  selector: 'app-campaines',
  templateUrl: './campaines.component.html',
  styleUrls: ['./campaines.component.scss']
})
export class CampainesComponent implements OnInit {

  arr:any = [];
  bankData:any = [];
  companyData:any= [];
  pageNo: any = 1;
  search: any;
  totalItem: any;
  isbankSuccess:boolean = false;
  iscompanySuccess:boolean = false;
  listData= [];
  tabType : number =  1;
  searchForm : FormGroup;
  length= 10;
  
  constructor(public router: Router,private fb: FormBuilder, private http: HttpService,private route: ActivatedRoute) {
    this.searchForm = this.fb.group({
      search : ['']
    })
   }

  ngOnInit() {
    this.getBankDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    this.getCompanyDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    this.getbankList();
    
    this.route.queryParams
    .subscribe(params => {      
      if (params) {
        this.tabType = params.type;
      }     
    })
    console.log(this.tabType);
    
  }

  onTabChange(type) {
    this.tabType = type;    
    this.router.navigate(['superadmin/campaines'], { queryParams: { type: this.tabType } });
  }

  getBankDetails(pageNo: any, search: any) {
    this.isbankSuccess = false;
    this.bankData = [];
    let StartPage = pageNo - this.length;
    var param = {
      "draw": 2,
      "columns": [
          {
              "data": "created_by__first_name",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          },
          {
              "data": "created_at",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          }
      ],
      "order": [
          {
              "column": 1,
              "dir": "undefined"
          }
      ],
      "start": StartPage,
      "length": this.length,
      "search": {
          "value": this.searchForm.get("search").value,
          "regex": false
      }
  };

    this.http.httpPostWithHeaderAndBody("getBankswithPagination", param)
      .subscribe((res: any) => {       
        console.log(res);
         
        if (res.code == 200) {
          this.bankData = res['data'];
          this.totalItem = res.recordsTotal;
          this.isbankSuccess = true;   
          this.iscompanySuccess = false;      
        } else if (res.code == 204) {
          this.bankData = [];
          this.isbankSuccess = true;
          this.iscompanySuccess = false;
        }
        else{
          this.isbankSuccess = false;
        }
      });
  }

  getCompanyDetails(pageNo: any, search: any) {
    this.iscompanySuccess = false;
    this.companyData = [];
    let StartPage = pageNo - this.length;
    var param = {
      "draw": 2,
      "columns": [
          {
              "data": "created_by__first_name",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          },
          {
              "data": "created_at",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          }
      ],
      "order": [
          {
              "column": 1,
              "dir": "undefined"
          }
      ],
      "start": StartPage,
      "length": this.length,
      "search": {
          "value": this.searchForm.get("search").value,
          "regex": false
      }
  };

    this.http.httpPostWithHeaderAndBody("getCompanieswithPagination", param)
      .subscribe((res: any) => {       
        console.log(res);
         
        if (res.code == 200) {
          this.companyData = res['data'];
          this.totalItem = res.recordsTotal;
          this.iscompanySuccess = true;         
        } else if (res.code == 204) {
          this.companyData = [];
          this.iscompanySuccess = true;
        }
        else{
          this.iscompanySuccess = false;
        }
      });

  }

  getPage(tab,pageNo: number) {
    this.pageNo = pageNo;
    if(tab == 1) {
      this.getBankDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    } else {
      this.getCompanyDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    }
  }

  submitSearch(tab) {
    if (this.searchForm.get("search").value !== "") {
      this.pageNo = 1;
      if(tab == 1) {
        this.getBankDetails(this.pageNo * this.length, this.searchForm.get("search").value);
      } else {
        this.getCompanyDetails(this.pageNo * this.length, this.searchForm.get("search").value);
      }
    }
  }

  onKeyUp(event, tab){
    this.search= event
    this.pageNo = 1;
    if(tab == 1) {
      this.getBankDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    } else {
      this.getCompanyDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    }
    
  }

  getbankList(){
    this.http.httpGetWithHeaderOnly("getBankLists")
      .subscribe((res: any) => {       
        console.log(res);
        if (res.code == 200) {
          this.listData = res.data;
        }
      });
  }

  onChange(event,pageNo: any){
    if(event !== '0'){
      
    this.iscompanySuccess = false;
    this.companyData = [];
    let StartPage = (pageNo* this.length) - this.length;
    var param = {
      "draw": 2,
      "columns": [
          {
              "data": "created_by__first_name",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          },
          {
              "data": "created_at",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          }
      ],
      "order": [
          {
              "column": 1,
              "dir": "undefined"
          }
      ],
      "start": StartPage,
      "length": this.length,
      "bank": event,
      "search": {
          "value": "",
          "regex": false
      }
    };

    this.http.httpPostWithHeaderAndBody("getBankswithPagination", param)
      .subscribe((res: any) => {       
        console.log(res);
        
        if (res.code == 200) {
          this.bankData = res['data'];
          this.totalItem = res.recordsTotal;
          this.isbankSuccess = true;  
          this.iscompanySuccess = true;       
        } else if (res.code == 204) {
          this.bankData = [];
          this.isbankSuccess = true;
          this.iscompanySuccess = true;
        }
        else{
          this.isbankSuccess = false;
        }
      });
    }
    if(event === '0'){
      this.getBankDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    }
  }

  selecttab(number){
    this.tabType = number   
  }


  download(type){
    var param = {
      "draw": 2,
      "columns": [
          {
              "data": "created_by__first_name",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          },
          {
              "data": "created_at",
              "name": "",
              "searchable": true,
              "orderable": true,
              "search": {
                  "value": "",
                  "regex": false
              }
          }
      ],
      "order": [
          {
              "column": 1,
              "dir": "undefined"
          }
      ],
      "start": 0,
      "length": this.totalItem,
      "search": {
          "value": '',
          "regex": false
      }
    };
    console.log(this.tabType);
    
    if(this.tabType === 1){
      this.http.httpPostWithHeaderAndBody("getBankswithPagination", param)
        .subscribe((res: any) => {                 
        if (res.code == 200) {    
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(res['data'][0]);
          let csv = res['data'].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');
          const contentType  = 'text/csv';
          const blob = new Blob([csvArray], { type: contentType });
          FileSaver.saveAs(blob, "Total_banks." + type);
        }
      });
    }
    if(this.tabType === 2){
      this.http.httpPostWithHeaderAndBody("getCompanieswithPagination", param)
      .subscribe((res: any) => {                 
        if (res.code == 200) {    
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(res['data'][0]);
          let csv = res['data'].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');
          const contentType  = 'text/csv';
          const blob = new Blob([csvArray], { type: contentType });
          FileSaver.saveAs(blob, "Total_companies." + type);
        }
      });
    }
    else{
      this.http.httpPostWithHeaderAndBody("getBankswithPagination", param)
        .subscribe((res: any) => {                 
        if (res.code == 200) {    
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(res['data'][0]);
          let csv = res['data'].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');
          const contentType  = 'text/csv';
          const blob = new Blob([csvArray], { type: contentType });
          FileSaver.saveAs(blob, "Total_banks." + type);
        }
      });
    }
  }


  delete(type,id) {
    this.tabType = type;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteHeader("deleteParticipants", id).subscribe((res: any) => {
          if(type == 1) {           
            Swal.fire("Deleted!", "Bank Details has been deleted.", "success");
            this.getBankDetails(this.pageNo * this.length, this.searchForm.get("search").value);
          } else {
            Swal.fire("Deleted!", "Comapny Details has been deleted.", "success");
            this.getCompanyDetails(this.pageNo * this.length, this.searchForm.get("search").value);
          }          
        });
      }
    });
  }

}
