import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: any = false;
  submit_success: any = false;
  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private router: Router,
    private commonService: CommonService,
  ) {
    this.loginForm = this.fb.group({
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]),
      password: new FormControl("", Validators.required),
    });
  }

  ngOnInit() {

  }

  avoidSpace(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32) return false;
  }

  login() {
    this.submitted = true;
    if (this.submitted && this.loginForm.valid) {
      this.submit_success = true
      let formdata: FormData = new FormData();
      formdata.append("user_name", this.loginForm.value.email.trim());
      formdata.append("password", this.loginForm.value.password.trim());
      this.http
        .httpPostWithoutHeader("login", formdata)
        .subscribe((res: any) => {
          if (res.code == 200) {
            localStorage.setItem("Authorization", res.data.token);
            localStorage.setItem("roleId", res.data.role.id);
            if(res.data.role.id == 5){
              localStorage.setItem("user_permission", JSON.stringify(res.data.user_permission));
            }
            this.commonService.presentsToast('success', "Login successful");
            if (res.data.role.id == 1) {
              this.router.navigateByUrl("/superadmin/dashboard");
            }
            if (res.data.role.id == 2) {
              this.router.navigateByUrl("/subadmin/dashboardSubadmin");
            }
            if (res.data.role.id == 4) {
              this.router.navigateByUrl("/subadmin/dashboardSubadmin");
            }
            if (res.data.role.id == 5) {
              this.router.navigateByUrl("/superadmin/dashboard");
            }
            this.submitted = false;
          }
          else{
            this.commonService.presentsToast('error', "Invalid credentials");
            this.submitted = false;
            this.submit_success = false;
          }
        });
    }
  }
}
