

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from "moment";
import { CommonService } from "../../../../services/common.service";

@Component({
  selector: 'app-addparticiapants',
  templateUrl: './addparticiapants.component.html',
  styleUrls: ['./addparticiapants.component.scss']
})
export class AddparticiapantsComponent implements OnInit {

  routeId: any;
  tabType: any;
  type: any;
  Id: string;
  sellDetailsForm: FormGroup;
  buyDetailsForm: FormGroup;
  detailsData: any[];
  submitted1: boolean = false;
  submitted2: boolean = false;
  moment: any = moment;
  inputReadonly = true;

  constructor(private route: ActivatedRoute,
    private http: HttpService,
    private fb: FormBuilder,
    private router: Router,
    private commonService: CommonService,) {
    this.type = this.route.snapshot.paramMap.get('type');
    this.Id = this.route.snapshot.paramMap.get('id');
    this.sellDetailsForm = this.fb.group({
      "loanPurpose": new FormControl('', [Validators.required, Validators.pattern(new RegExp("\\S"))]),
      "collateralType": new FormControl('', [Validators.required, Validators.pattern(new RegExp("\\S"))]),
      "amount": new FormControl('', Validators.required),
      "expiryDate": new FormControl('', Validators.required),
    });
    this.buyDetailsForm = this.fb.group({
      "reachOut": new FormControl('', [Validators.required, Validators.pattern(new RegExp("\\S"))]),
      "specialTerms": new FormControl('', [Validators.required, Validators.pattern(new RegExp("\\S"))]),
      "expiryDate": new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params) {
          this.tabType = params.type;
        }        
      })      
    this.getParticipantsDetailsById();
    if (this.type == "view") {
      this.isDisabled(true);
    }
  }

  toggleInputReadonly() {
    this.inputReadonly = !this.inputReadonly;
  }

  onTabChange(type) {
    this.tabType = type
  }

  isDisabled(value: boolean) {
    this.sellDetailsForm.controls['loanPurpose'].disable();
    this.sellDetailsForm.controls['collateralType'].disable();
    this.sellDetailsForm.controls['amount'].disable();
    this.sellDetailsForm.controls['expiryDate'].disable();
    this.buyDetailsForm.controls['reachOut'].disable();
    this.buyDetailsForm.controls['specialTerms'].disable();
    this.buyDetailsForm.controls['expiryDate'].disable();
  }

  getParticipantsDetailsById() {
    this.http.httpGetWithHeaderAndId("getParticipantsDetailsById", this.Id).subscribe((res: any) => {
      if (res.code == 200) {
        this.detailsData = res.data;
        this.sellDetailsForm.patchValue({
          "loanPurpose": this.detailsData['loan_purpose'],
          "collateralType": this.detailsData['collateral_type'],
          "amount": this.detailsData['amount'],
          "expiryDate": this.detailsData['expiry_date'],
        })
        this.buyDetailsForm.patchValue({
          "reachOut": this.detailsData['special_terms'],
          "specialTerms": this.detailsData['reach_out'],
          "expiryDate": this.detailsData['expiry_date'],
        })
      }
    })
  }

  updateParticipants() {
    let param;
    if (this.tabType == 1) {
      this.submitted1 = true;
      if (this.sellDetailsForm && this.sellDetailsForm.valid) {
        if (this.sellDetailsForm.value.expiryDate != null) {
          var date1 = moment(this.sellDetailsForm.value.expiryDate).format('YYYY-MM-DD');
        }
        param = {
          loan_purpose: this.sellDetailsForm.value.loanPurpose != null ? this.sellDetailsForm.value.loanPurpose.trim() : '',
          collateral_type: this.sellDetailsForm.value.collateralType != null ? this.sellDetailsForm.value.collateralType.trim() : '',
          amount: this.sellDetailsForm.value.amount != null ? this.sellDetailsForm.value.amount: '',
          expiry_date: date1
        }
      } else {
        return false;
      }
    } else {
      this.submitted2 = true;
      if (this.buyDetailsForm && this.buyDetailsForm.valid) {
        console.log(this.buyDetailsForm.value);
        
        if (this.buyDetailsForm.value.expiryDate != null) {
          var date2 = moment(this.buyDetailsForm.value.expiryDate).format('YYYY-MM-DD');
        }
        param = {
          reach_out: this.buyDetailsForm.value.reachOut != null ? this.buyDetailsForm.value.reachOut.trim() : '',
          special_terms: this.buyDetailsForm.value.specialTerms != null ? this.buyDetailsForm.value.specialTerms.trim() : '',
          expiry_date: date2
        }
      } else {
        return false;
      }
    }
    this.http.httpPutWithDataHeaderAndBodyWithid("updateParticipants", this.Id, param).subscribe((res: any) => {
      if (res.code == 200) {
        this.commonService.presentsToast('success', res.message);
        this.router.navigate(['superadmin/participants'], { queryParams: { type: this.tabType } });
      }
    })
  }

}
