import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";
import * as FileSaver from "file-saver";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  arr = [];
  allSectorList: any[] = [];
  allUsersList: any = [];
  totalItem: any;
  pageNo: any = 1;
  imageUrl: any;
  baseUrl = environment.imageUrl;
  searchValue: any = "";
  dataId: any;
  showTable: boolean = false;
  id = 1;
  searchForm: FormGroup;
  search: any;
  length = 10;
  tabType: string;
  searchIconClass: string = 'fa-search';

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private http: HttpService,
    private commonService: CommonService
  ) {
    this.searchForm = this.fb.group({
      search: [""],
    });
  }

  ngOnInit() {
    this.getAllSubscriberList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  getAllSubscriberList(pageNo: any, search: any) {
    this.allUsersList = [];
    this.showTable = false;
    let page = pageNo - this.length;
    let param = {
      draw: 2,
      columns: [
        {
          data: "job_title",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "first_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "organization_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "id",
          name: "",
          searchable: false,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 3,
          dir: "undefined",
        },
      ],
      start: page,
      length: this.length,
      search: {
        value: search,
        regex: false,
      },
    };
    this.http
      .httpPostWithHeaderAndBody("getAllSubscriberList", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          console.log(res.data);
          this.allUsersList = res.data;
          this.totalItem = res.recordsTotal;
          this.showTable = true;
        } else {
          this.showTable = true;
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getAllSubscriberList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  submitSearch() {
    if (this.searchForm.get("search").value !== "") {
      this.pageNo = 1;
      this.getAllSubscriberList(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    }
  }

  // onKeyUp(event) {
  //   this.search = event;
  //   this.pageNo = 1;
  //   this.getAllSubscriberList(
  //     this.pageNo * this.length,
  //     this.searchForm.get("search").value
  //   );
  // }

  onKeyUp(event: string) {
    this.search = event;
    this.pageNo = 1;
    if (event && event.trim().length > 0) {
      this.searchIconClass = 'fa-times';
    } else {
      this.searchIconClass = 'fa-search';
    }
  
    this.getAllSubscriberList(this.pageNo * this.length, this.searchForm.get("search").value);
  }

  clearSearch() {
    if (this.searchForm.get("search").value.length > 0) {
      this.searchForm.get("search").setValue('');
      this.searchIconClass = 'fa-search';
      this.pageNo = 1;
      this.getAllSubscriberList(this.pageNo * this.length, '');
    }
  }

  download(type) {
    let page = 0;
    let param = {
      draw: 2,
      columns: [
        {
          data: "job_title",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "first_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "organization_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "id",
          name: "",
          searchable: false,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 3,
          dir: "undefined",
        },
      ],
      start: page,
      length: this.totalItem,
      search: {
        value: '',
        regex: false,
      },
    };
    console.log(this.searchForm.get("search").value);

    this.http
      .httpPostWithHeaderAndBody("getAllUsersBySectorId", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
          const header = Object.keys(res["data"][0]);
          let csv = res["data"].map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(",")
          );
          csv.unshift(header.join(","));
          let csvArray = csv.join("\r\n");
          const contentType = "text/csv";
          const blob = new Blob([csvArray], { type: contentType });
          FileSaver.saveAs(blob, "Users." + type);
        }
      });
  }


  toggle(event, id) {
    console.log(event.checked,id)
    // this.id = number;
    var param;
    if (event.checked == false) {
      param = {
        is_active: false,
        sub_id:id
      };
    } else {
      param = {
        is_active: true,
        sub_id:id
      };
    }
    console.log(param,'ppp');
    this.http
      .httpPostWithFormDataHeaderAndBody("enableDisableSubscriber", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.commonService.presentsToast(
            "success",
            "Status Changed successfully"
          );
        }
      });
  }

  deleteSubscription(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http
          .httpPutWithDataHeaderAndBodyWithid("deleteSubscriptionbyId", id,'')
          .subscribe((res: any) => {
            Swal.fire("Deleted!", "Subscription has been deleted.", "success");
            this.getAllSubscriberList(
              this.pageNo * this.length,
              this.searchForm.get("search").value
            );
          });
      }
    });
  }

  // download(type) {
  //   let page = 0;
  //   let param = {
  //     draw: 2,
  //     columns: [
  //       {
  //         data: "job_title",
  //         name: "",
  //         searchable: true,
  //         orderable: true,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //       },
  //       {
  //         data: "first_name",
  //         name: "",
  //         searchable: true,
  //         orderable: true,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //       },
  //       {
  //         data: "organization_name",
  //         name: "",
  //         searchable: true,
  //         orderable: true,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //       },
  //       {
  //         data: "id",
  //         name: "",
  //         searchable: false,
  //         orderable: true,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //       },
  //     ],
  //     order: [
  //       {
  //         column: 3,
  //         dir: "undefined",
  //       },
  //     ],
  //     start: page,
  //     length: 1152,
  //     search: {
  //       value: '',
  //       regex: false,
  //     },
  //   };
  
  
  //   this.http
  //     .httpPostWithHeaderAndBody("getAllUsersBySectorId", param)
  //     .subscribe((res: any) => {
  //       if (res.code == 200) {
  //         const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  //         const header = Object.keys(res["data"][0]);
  //         let csv = res["data"].map((row) =>
  //           header
  //             .map((fieldName) => JSON.stringify(row[fieldName], replacer))
  //             .join(",")
  //         );
  //         csv.unshift(header.join(","));
  //         let csvArray = csv.join("\r\n");
  //         const contentType = "text/csv";
  //         const blob = new Blob([csvArray], { type: contentType });
  //         FileSaver.saveAs(blob, "Users." + type);
  //       }
  //     });
  // }

}
