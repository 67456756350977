import { Component, OnInit } from '@angular/core';
declare var $: any;
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboardData:any;
  isSuccess:boolean = false;
  selectType= 2;
  totaladvertisementData: any;

  constructor(private http:HttpService) { }
  
  ngOnInit() {
    this.getDashboardDetails();
    this.getTotalAdvertisementGraph();
  }

  getDashboardDetails() {
    this.http.httpGetWithHeaderOnly("getDashboardDetails").subscribe((res:any)=>{
      if(res.code == 200) {
        this.dashboardData = res.data;
        this.isSuccess = true;
      }
    })
  }

  onTypeChange(graphType,event) {
    this.selectType = event;
    console.log(this.selectType)
    console.log(graphType);
    
    if(graphType == "total_advertisement") {
      this.getTotalAdvertisementGraph();
    } else if (graphType == "total_revenue") {
      this.getTotalRevenueGraph();
    }
  }

  getTotalAdvertisementGraph(){
    this.totaladvertisementData = [];   
    let param = {
      filter_type: + this.selectType,
    }
       
    this.http.httpPostWithHeaderAndBody("getTotalAdvertisementGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.totaladvertisementData = res['data'];
          this.isSuccess = true;
          console.log(this.totaladvertisementData);
          
        }
      })
  }
  getTotalRevenueGraph(){}
}
