import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-applyjob',
  templateUrl: './applyjob.component.html',
  styleUrls: ['./applyjob.component.scss']
})
export class ApplyjobComponent implements OnInit {

  arr = [];
  applicant_data: any[]=[];
  job_id: string;
  searchForm : FormGroup;
  search: any;
  pageNo: any = 1;
  totalItem: any;
  image_url = environment.imageUrl;
  showTable: boolean = false;
  length= 10;

  constructor(public router: Router, private http: HttpService, private route: ActivatedRoute, private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      search : ['']
    })
    this.job_id = this.route.snapshot.paramMap.get("id");
    console.log(this.job_id);
    
  }

  ngOnInit() {
    this.getapplicantDetails(this.pageNo * this.length, this.searchForm.get("search").value);
  }


  getapplicantDetails(pageNo: any, search: any){
    let StartPage = pageNo - this.length;
    let param = {
      "draw": 2,
      "columns": [
        {
          "data": "id",
          "name": "",
          "searchable": false,
          "orderable": true,
          "search": {
              "value": "",
              "regex": false
          }
        }
      ],
      "order": [
        {
          "column": 0,
          "dir": "undefined"
        }
      ],
      "start": StartPage,
      "length": this.length,
      "search": {
        "value": this.searchForm.get("search").value,
        "regex": false
      }
    }
    this.http.httpPostWithHeaderAndBodyWithId("getJobApplicantDetails",this.job_id,param).subscribe((res:any)=>{
      if(res.code == 200){
        this.applicant_data = res.data;
        console.log(res.data)
        this.totalItem = res.recordsTotal;
        this.showTable = true;
      }
      else{
        this.applicant_data = [];
        this.showTable = true;
      }
    })
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getapplicantDetails(this.pageNo * this.length, this.searchForm.get("search").value);
  }

  submitSearch() {
    if (this.search.value !== "") {
      this.pageNo = 1;
      this.getapplicantDetails(this.pageNo * this.length, this.searchForm.get("search").value);
    }
  }

  onKeyUp(event){
    this.search= event
    this.pageNo = 1;
    this.getapplicantDetails(this.pageNo * this.length, this.searchForm.get("search").value); 
  }

  delete(id){
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteHeader("deleteJob", id).subscribe((res: any) => {
          Swal.fire("Deleted!", "Banner has been deleted.", "success");
          this.getapplicantDetails(this.pageNo * this.length, this.searchForm.get("search").value);
        });
      }
    });
  }

}

