import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { MustMatch } from "src/app/services/must-match.validators";
declare var $: any;

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  submitted: boolean = false;
  id: any;
  currentUrl: any;
  isReset: boolean = false;
  msg: string;

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentUrl = this.route.snapshot["_routerState"].url;
    console.log(this.currentUrl);
    let pathId = this.currentUrl.split("?").pop();
    this.id = pathId.split("id=").pop();
    console.log(this.id);
    var param = this.id;
    let formData: any = new FormData();
    formData.append("encoded_id", this.id);
    this.http
      .httpGetWithoutHeaderWithParam("checkPassword", param, formData)
      .subscribe((res: any) => {
        if (res.code == 200) {
          if (res.message == "Link Not Expired") this.isReset = true;
        }
        else{
          this.msg = res.message;
        }
      });
  }

  ngOnInit() {
    this.resetForm = this.fb.group(
      $.extend(
        {},
        {
          newPassword: new FormControl("", [
            Validators.required,
            Validators.minLength(8),
          ]),
          confirmPassword: new FormControl("", Validators.required),
        }
      ),
      $.extend(
        {},
        {
          validator: MustMatch("newPassword", "confirmPassword"),
        }
      )
    );
  }

  submit() {
    this.submitted = true;
    if (this.submitted && this.resetForm.valid) {
      let formData: any = new FormData();
      formData.append("id", this.id);
      formData.append("new_password", this.resetForm.value.newPassword.trim());
      this.http
        .httpPutWithoutHeader("resetPassword", this.id, formData)
        .subscribe((res: any) => {
          if (res.code == 200) {
            this.router.navigateByUrl("/auth/login");
          }
        });
    }
  }
}
