import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent implements OnInit {
  adsData = [];
  pageNo: any = 1;
  totalItem: any;
  isadsSuccess:boolean = false;
  searchForm : FormGroup;
  search: any;
  length = 10;

  constructor(public router: Router,private http: HttpService, private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      search : ['']
    })
   }

  ngOnInit() {
    this.getAcceptadsList(this.pageNo * this.length, this.searchForm.get("search").value);
  }

  getAcceptadsList(pageNo: any, search: any) {
    this.isadsSuccess = false;
    this.adsData = [];
    let StartPage = pageNo - this.length;
    var param = {
      draw: 2,
      columns: [
        {
          data: "id",
          name: "",
          searchable: false,
          orderable: true,
          search: {
            value: "",
            regex: false
          }
        }
      ],
      order: [
        {
          column: 0,
          dir: "undefined"
        }
      ],
      start: StartPage,
      length: this.length,
      search: {
        value: this.searchForm.get("search").value,
        regex: false
      }
    };

    this.http.httpPostWithHeaderAndBody("acceptadvertisementswithpagination", param)
      .subscribe((res: any) => {        
        if (res.code == 200) {
          this.adsData = res.data;
          this.totalItem = res.recordsTotal;
          this.isadsSuccess = true;         
        } else if (res.code == 204) {
          this.adsData = [];
          this.isadsSuccess = true;
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getAcceptadsList(this.pageNo * this.length, this.searchForm.get("search").value);
  }

  submitSearch() {
    if (this.search.value !== "") {
      this.pageNo = 1;
      this.getAcceptadsList(this.pageNo * this.length, this.searchForm.get("search").value);
    }
  }

  deleteAcceptedads(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteHeader("deletAdvertisements", id).subscribe((res: any) => {
          Swal.fire("Deleted!", "Advertisement has been deleted.", "success");       
          this.getAcceptadsList(this.pageNo * this.length, this.searchForm.get("search").value);    
        });
      }
    });
  }

  onKeyUp(event){
    this.search= event
    this.pageNo = 1;
    this.getAcceptadsList(this.pageNo * this.length, this.searchForm.get("search").value);
    
  }

}
