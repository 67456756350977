export class apiList {
  login: string = "api/auth/admin/login/";
  forgotPassword: string = "api/auth/forgot-password/";
  checkPassword: string = "api/user/check-password-link/";
  getAllJobs: string = "api/jobs/get-list-web-pagination/";
  createJobs: string = "api/jobs/create/";
  updateJobs: string = "api/jobs/update/";
  getJobById: string = "api/jobs/get-details-by-id/";
  uploadMedia: string = "api/upload/media/";
  deleteJob: string = "api/jobs/delete/";
  resetPassword: string = "api/auth/reset-password/";
  getSectorList: string = "api/sector/list/";
  getAllUsersBySectorId: string = "api/user/get-user-details-by-sector-id/";
  getAllUserWorkExperienceDetailsByUserId: string =
    "api/user/get-work-experience-details-by-user-id/";
  getAllUserEducationDetailsByUserId: string =
    "api/user/get-education-details-by-user-id/";
  getNewsFeedByUserId: string = "api/feed/get-feed-by-user-id/";
  getProfileDetailsByUserId: string = "api/user/profile-details/";
  updateUserById: string = "api/user/update-user-details-by-id/";
  updateEducationalDetailById: string = "api/user/update-education-info-by-pk/";
  updateWorkExperienceById: string = "api/user/update-work-experience-by-pk/";
  getCSMDetails: string = "api/user/get-cms-details/";
  createUpdateCms: string = "api/user/create-update-cms/";
  getAnalyticalDetails: string = "api/user/get-analytical-details/";
  getDashboardDetails: string = "api/user/get-dashboard-count-details/";
  getAllPermissionListWithPagination: string =
    "api/role/get-all-permission-list-with-pagination/";
  createUpdateRolePermission: string =
    "api/role/create-update-role-permission/";
  getAllUsersForPermission: string = "api/role/get-all-users-for-permission/";
  getNewUserGraph: string = "api/user/get-new-user-graph/";
  getTotalUserGraph: string = "api/user/get-total-user-graph/";
  getWebPaginationList = "api/participants/get-web-pagination-list/";
  getParticipantsDetailsById = "api/participants/get-details-by-id/";
  getReachOutToBuyList = "api/participations/get-reach-out-to-buy-list/";
  getReachOutToBuyDetails = "api/participants/get-reach-out-buy-detials/";
  updateParticipants = "api/participants/update/";
  deleteParticipants = "api/participants/delete/";
  getAllEevents = "api/calander/get-all-events/";
  addEvent = "api/calender/add-event/";
  deleteEvent = "api/calander/delete-event/";
  updateEvent = "api/calender/update-event/";
  getnewadvertisementswithpagination =
    "api/admin/advertisements/new-advertisements-list-with-paginaiton/";
  acceptadvertisementswithpagination =
    "api/admin/advertisements/accept-advertisements-list-with-paginaiton/";
  deletAdvertisements = "api/advertisements/delete/";
  updateAdvertisementStatus =
    "api/admin/advertisements/update-advertisements-status/";
  changePassword = "api/user/change-password/";
  getTotalAdvertiserGraph = "api/user/get-total-advertiser-graph/";
  getActiveAdvertisementGraph =
    "api/user/get-total-active-advertisments-graph/";
  getTotalAdvertisementGraph = "api/user/get-total-advertisments-graph/";
  getdataCount = "api/user/total-user-participation/";
  getUserDetails = "api/user/get-user-details/";
  editProfile = "api/user/update-user-details-by-id/";
  getBankswithPagination = "api/company/get-web-pagination-list/1/";
  getCompanieswithPagination = "api/company/get-web-pagination-list/2/";
  getBankLists = "api/user/get-all-bank-list/";
  getSubadminDashboardCount = "api/user/get-dashboard-count-details-sub-admin/";
  getTotalImpressionGraph = "api/user/get-total-impressions-graph/";
  getTotalClicksGraph = "api/user/get-total-clicks-graph/";
  getCampaignList = "api/campaign/get-all-list/";
  getCamapignByCategory = "api/advertisements/pagination-list-by-category_id/";
  updateCampaignAdvertise =
    "api/admin/advertisements/active-deactive-advertisements/";
  deleteUserbyId = "api/user/delete-user-by-id/";
  getLoanDetails = "api/participants/get-web-pagination-list-by-user/";
  getAdvertisementById = "api/advertisements/details-by-id/";
  updateUserStatus = "api/user/update-user-profile-status/";
  createEducationInfo = "api/user/create-education-info/";
  getProfessionList = "api/profession/get-all-list/";
  createAdvertisement = "api/advertisements/create-ads/";
  updateAdvertisementById = "api/advertisements/update-ads/";
  createWorkExp = "api/user/create-work-experience/";
  notificationSetting = "api/user/notification-settings-list/";
  getJobApplicantDetails = "api/jobs/get-applicant-pagination-list/";
  changeJobStatus = "api/jobs/change-job-status/";
  addSubAdmin = "api/admin/add-sub-admin/";
  getGroupList = "api/group/get-group-pagination-list/";
  getGroupInfo = "api/group/get-group-members-list/";
  deleteGroup = "api/group/delete-group/";
  csvapi = "api/cre/read-csv/"
  getUploadCsvList = "api/cre/get-cre-leads-by-pagination/"
  deleteLeadbyId = "api/delete-cre-by-id/";
  singleLeadDetails = "api/cre/get-cre-leads-by-id/"
  getColletralApi = "api/cre/get-all-collateral-type/"
  getAllState = "api/cre/get-all-states/"
  updateLeadById = "api/update-cre-by-id/"
  getAllSubscriberList = "api/subscription/get-all-enterprise-subscriber-list-with-pagination/"
  getCompany ="api/subscription/get-all-comapines/"
  getUserListViaCompany = "api/subscription/get-user-details-by-company-id/"
  userSubscription = "api/subscription/change-user-subscription/"
  enableDisableSubscriber = "api/subscription/enable-disable-enterprise-subscriber/"
  enableDisableSubscriberViaUser = "api/subscription/enable-disable-subscription/"
  addSubscription = "api/subscription/add-enterprise-subscription/"
  editSubscription = "api/subscription/edit-enterprise-subscription/"
  deleteSubscriptionbyId = "api/subscription/delete-enterprise-subscriber/"
  getSubscribeById = "api/subscription/get-subscription-by-id/"
  addEmailSubscription = "api/subscription/add-enterprise-subscription-user-by-mail/"
  getAllUserviaSubscription = "api/subscription/get-all-subscriber-list-with-pagination/"
}
