import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { CommonService } from "../../../services/common.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  countData: any;
  isSuccess = false;
  userData: any;
  options = {
    types: [],
    componentRestrictions: {
      country: [],
    },
  };
  formattedaddress = " ";
  longitude: any;
  latitude: any;
  editProfileForm: FormGroup;
  imageToUpload: File = null;
  imageData: any;
  imageToUpload2: File = null;
  imageData2;
  submitted: boolean = false;
  id: string = "";
  image_data = "";
  imgBaseUrl = environment.imageUrl;
  proile_img_data = "";
  cover_img_data = "";

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.editProfileForm = this.fb.group({
      first_name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]+$/),
      ]),
      last_name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]+$/),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      role: new FormControl(""),
      address: new FormControl("", [Validators.required]),
      phone_no: new FormControl("", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
    });
  }

  ngOnInit() {
    this.getCountDetails();
    this.getUserDetails();
  }

  getCountDetails() {
    this.http.httpGetWithHeaderOnly("getdataCount").subscribe((res: any) => {
      if (res.code == 200) {
        this.countData = res.data;
        this.isSuccess = true;
      }
    });
  }

  getUserDetails() {
    this.http.httpGetWithHeaderOnly("getUserDetails").subscribe((res: any) => {
      if (res.code == 200) {
        console.log(res);
        this.id = res.data.id;
        this.userData = res.data;
        this.imageData = res.data.profile_image_url;
        this.image_data = res.data.profile_image_url;
        this.patchValue(this.userData);
      }
    });
  }

  handleFileInput(files: FileList) {
    this.imageToUpload = files.item(0);
    console.log(this.imageToUpload);
    var reader = new FileReader();
    reader.readAsDataURL(files.item(0));
    reader.onload = (event) => {
      this.imageData = reader.result;
    };
  }

  handleFileInput2(files: FileList) {
    this.imageToUpload2 = files.item(0);
    console.log(this.imageToUpload);
    var reader = new FileReader();
    reader.readAsDataURL(files.item(0));
    reader.onload = (event) => {
      this.imageData2 = event.target;
    };
  }

  onInputChange(event) {
    var locationName: any = "";
    locationName = this.editProfileForm.value.address;
    if (locationName != this.formattedaddress) {
      this.editProfileForm.controls["address"].reset();
    }
  }

  AddressChange(address: any) {
    address.address_components.forEach((res) => {
      if (
        res.types.includes("locality") ||
        res.types.includes("postal_town") ||
        res.types.includes("sublocality") ||
        res.types.includes("sublocality_level_1") ||
        res.types.includes("sublocality_level_2") ||
        res.types.includes("sublocality_level_3") ||
        res.types.includes("sublocality_level_4") ||
        res.types.includes("sublocality_level_5")
      ) {
        this.formattedaddress = res.long_name;
      }
      this.editProfileForm.controls["address"].patchValue(
        this.formattedaddress
      );
    });
    this.longitude = address.geometry.location.lng();
    this.latitude = address.geometry.location.lat();
    console.log(this.longitude);
  }

  patchValue(data) {
    this.editProfileForm.patchValue({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      role: data.role["name"],
      address: data.address,
      phone_no: data.phone_no,
    });
    this.longitude = data.longitude;
    this.latitude = data.latitude;
  }

  update() {
    this.submitted = true;
    if (this.imageToUpload !== null && this.editProfileForm.valid) {
      var formData = new FormData();
      formData.append("profile_image", this.imageToUpload);
      formData.append(
        "first_name",
        this.editProfileForm.controls["first_name"].value
      );
      formData.append(
        "last_name",
        this.editProfileForm.controls["last_name"].value
      );
      formData.append(
        "phone_no",
        this.editProfileForm.controls["phone_no"].value
      );
      formData.append(
        "address",
        this.editProfileForm.controls["address"].value
      );
      formData.append("longitude", this.longitude);
      formData.append("latitude", this.latitude);
      this.http
        .httpPutWithFormDataHeaderAndBodyWithid(
          "editProfile",
          this.id,
          formData
        )
        .subscribe((res: any) => {
          if (res.code) {
            this.submitted = false;
            this.commonService.isProfileUpdated.next(true);
            this.getUserDetails();
            this.commonService.presentsToast(
              "success",
              "User updated successfully"
            );
            this.router.navigate(["superadmin/dashboard"]);
          }
        });
    }
    if (this.imageToUpload === null && this.editProfileForm.valid) {
      var formData = new FormData();
      formData.append(
        "first_name",
        this.editProfileForm.controls["first_name"].value
      );
      formData.append(
        "last_name",
        this.editProfileForm.controls["last_name"].value
      );
      formData.append(
        "phone_no",
        this.editProfileForm.controls["phone_no"].value
      );
      formData.append(
        "address",
        this.editProfileForm.controls["address"].value
      );
      formData.append("longitude", this.longitude);
      formData.append("latitude", this.latitude);

      this.http
        .httpPutWithFormDataHeaderAndBodyWithid(
          "editProfile",
          this.id,
          formData
        )
        .subscribe((res: any) => {
          if (res.code) {
            this.submitted = false;
            this.commonService.presentsToast(
              "success",
              "User updated successfully"
            );
            this.router.navigate(["superadmin/dashboard"]);
          }
        });
    }
  }
}
