import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import * as FileSaver from "file-saver";
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  analyicsData:any;
  newUserData:any;
  totalUserData:any;
  isSuccess:boolean = false;
  total_userFrom: any = "";
  total_userTo: any = "";
  new_userFrom: any = "";
  new_userTo: any = "";
  type: any = "";
  selectType = 1;
  datePipe = new DatePipe("en-US");
  moment: any = moment;
  totaladvertisersData: any;
  activeAdvertisementData: any;
  total_advertiseFrom: any = "";
  total_advertiseTo: any = "";
  total_activeFrom: any = "";
  total_activeTo: any = "";
  todayDate: any;
  isgraph: boolean = false;
  inputReadonly = true;

  constructor(private http:HttpService) { }

  ngOnInit() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    this.todayDate = new Date(mm + "/" + dd + "/" + yyyy);
    this.getAnalyticalDetails();
    this.getTotalUserGraph();
    this.getNewUserGraph();    
    this.getTotalAdvertisers();
    this.getActiveAdvertisement();
  }

  toggleInputReadonly() {
    this.inputReadonly = !this.inputReadonly;
  }

  getAnalyticalDetails() {
    this.http.httpGetWithHeaderOnly("getAnalyticalDetails").subscribe((res:any)=>{
      if(res.code == 200) {
        this.analyicsData = res.data;
        this.isSuccess = true;
      }
    })
  }

  onTypeChange(graphType,event) {
    this.selectType = event;
    if(graphType == "total_user") {
      this.getTotalUserGraph();
    } else if (graphType == "new_user") {
      this.getNewUserGraph();
    }
    else if(graphType == "total_advertisers"){
      this.getTotalAdvertisers();
    }
    else if(graphType == "active_advertisement"){
      this.getActiveAdvertisement();
    }
  }

  onToDate(graphType,event) {
    this.type = graphType;
    if(this.type == "total_user" && this.total_userFrom != "") {
      this.getTotalUserGraph();
    } else if (this.type == "new_user" && this.new_userFrom != "") {
      this.getNewUserGraph();
    } else if (this.type == "total_advertisers" && this.total_advertiseFrom != "") {
      this.getTotalAdvertisers();
    } else if(this.type == "active_advertisement" && this.total_activeFrom != ""){
      this.getActiveAdvertisement();
    }

  }

  getTotalUserGraph() {
    this.totalUserData = [];
    let param;
    if(this.total_userFrom != undefined && this.total_userTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.total_userFrom).format('YYYY-MM-DD'),
        end_date:  moment(this.total_userTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }    
    this.http.httpPostWithHeaderAndBody("getTotalUserGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.totalUserData = res['data'];
          this.isgraph = true;
        }
        else{
          this.totalUserData = []
          this.isgraph = true;
        }
      });
  }

  getNewUserGraph() {
    this.newUserData = [];
    let param;
    if(this.new_userFrom != undefined && this.new_userTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.new_userFrom).format('YYYY-MM-DD'),
        end_date: moment(this.new_userTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }  
    this.http.httpPostWithHeaderAndBody("getNewUserGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.newUserData = res['data'];
        }
      });
  }

  getTotalAdvertisers() {
    this.totaladvertisersData = [];
    let param;
    if(this.total_advertiseFrom != undefined && this.total_advertiseTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.total_advertiseFrom).format('YYYY-MM-DD'),
        end_date:  moment(this.total_advertiseTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }    
    this.http.httpPostWithHeaderAndBody("getTotalAdvertiserGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.totaladvertisersData = res['data'];
        }
      });
  }

  getActiveAdvertisement() {
    this.activeAdvertisementData = [];
    let param;
    if(this.total_activeFrom != undefined && this.total_activeTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.total_activeFrom).format('YYYY-MM-DD'),
        end_date:  moment(this.total_activeTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }    
    this.http.httpPostWithHeaderAndBody("getActiveAdvertisementGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.activeAdvertisementData = res['data'];
        }
      });
  }

  downloadTotalUser(type) {
    let param;
    if(this.total_userFrom != undefined && this.total_userTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.total_userFrom).format('YYYY-MM-DD'),
        end_date:  moment(this.total_userTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }    
    this.http.httpPostWithHeaderAndBody("getTotalUserGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(res['data'][0]);
          let csv = res['data'].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');
          const contentType  = 'text/csv';
          const blob = new Blob([csvArray], { type: contentType }); // you can change the type
          FileSaver.saveAs(blob, "Total_users." + type);
        }
      });
  }

  downloadNewUser(type){
    let param;
    if(this.new_userFrom != undefined && this.new_userTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.new_userFrom).format('YYYY-MM-DD'),
        end_date: moment(this.new_userTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }  
    this.http.httpPostWithHeaderAndBody("getNewUserGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(res['data'][0]);
          let csv = res['data'].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');
          const contentType  = 'text/csv';
          const blob = new Blob([csvArray], { type: contentType }); // you can change the type
          FileSaver.saveAs(blob, "New_users." + type);
        }
      });
  }

  downloadAdvertisers(type){
    let param;
    if(this.total_advertiseFrom != undefined && this.total_advertiseTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.total_advertiseFrom).format('YYYY-MM-DD'),
        end_date:  moment(this.total_advertiseTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }    
    this.http.httpPostWithHeaderAndBody("getTotalAdvertiserGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(res['data'][0]);
          let csv = res['data'].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');
          const contentType  = 'text/csv';
          const blob = new Blob([csvArray], { type: contentType });
          FileSaver.saveAs(blob, "Total_advertisers." + type);
        }
      }); 
  }

  downloadAdvertisement(type){
    let param;
    if(this.total_activeFrom != undefined && this.total_activeTo !== "") {
      param = {
        filter_type: + this.selectType,
        start_date: moment(this.total_activeFrom).format('YYYY-MM-DD'),
        end_date:  moment(this.total_activeTo).format('YYYY-MM-DD'),
      }
    } else {
      param = {
        filter_type: + this.selectType,
      }
    }    
    this.http.httpPostWithHeaderAndBody("getActiveAdvertisementGraph", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(res['data'][0]);
          let csv = res['data'].map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');
          const contentType  = 'text/csv';
          const blob = new Blob([csvArray], { type: contentType });
          FileSaver.saveAs(blob, "Active_advertisement." + type);
        }
      });
  }

}
