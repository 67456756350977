import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss']
})

export class AddSubscriptionComponent implements OnInit {

  routeId: any;
  type: any;
  pageNo: any = 1;
  userId: string;
  allUsersList: any = [];
  createAdForm: FormGroup;
  fileData: File;
  imageUrl: string = "";
  submitted: boolean = false;
  jobData: any;
  options = {
    types: [],
    componentRestrictions: {
      country: [],
    },
  };
  formattedaddress = " ";
  subscriptionData: any;
  longitude: any;
  latitude: any;
  companyData: any[] = [];
  fileId: any;
  todayDate: any;
  search: any;
  storeCompanyId:any
  totalItem: any;
  subscriptionId:any;
  length = 10;
  userList:any[] = [];
  storeMultipleUserIds:any[] = [];
  types3: any[] = [
    { value: 1, viewValue: "3 Months" },
    { value: 2, viewValue: "6 Months" },
    { value: 3, viewValue: "12 Months" },
  ];
  types2: any[] = [
    { value: true, viewValue: 'Yes' },
    { value: false, viewValue: 'No' },
  ];
  today: Date = new Date()

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpService,
    private router: Router,
    private commonService: CommonService
  ) {
    window.scrollTo(0, 0);
    this.createAdForm = this.fb.group({
      bussiness_name: new FormControl("", [Validators.required,Validators.maxLength(250)]),
      contact_preson: new FormControl("", [Validators.required,Validators.maxLength(100)]),
      person_role: new FormControl("", [Validators.required,Validators.maxLength(100)]),
      person_address: new FormControl("", [Validators.required,Validators.maxLength(500)]),
      person_phone_number: new FormControl("", [Validators.required,Validators.maxLength(15)]),
      person_city: new FormControl("", [Validators.required,Validators.maxLength(100)]),
      person_state: new FormControl("", [Validators.required,Validators.maxLength(100)]),
      person_zipcode: new FormControl("", [Validators.required,Validators.maxLength(8)]),
      monthly_rate: new FormControl("", [Validators.required]),
      total_revenue: new FormControl(),
      comments: new FormControl("", [Validators.maxLength(500)]),
      term: new FormControl("", [Validators.required]),   
      created_at: new FormControl(""), 
    });
    this.type = this.route.snapshot.paramMap.get("type");
    this.subscriptionId = this.route.snapshot.paramMap.get("id");
    console.log(this.subscriptionId,'MMM');
    if (this.type === 'view') {
      this.createAdForm.disable();
    }
    if(this.subscriptionId){
      console.log('222');
      this.getSusbcriptionData();
      this.getListUser(this.pageNo * this.length)
    }
    console.log(this.route.snapshot);
    
  }

  ngOnInit() {
    console.log(1);
   
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    this.todayDate = new Date(mm + "/" + dd + "/" + yyyy);
    this.getcompanyData();

  }

  onKeyUp(event) {
    this.search = event;
    this.pageNo = 1;
    this.getListUser(
      this.pageNo * this.length
    );
  }


  getSusbcriptionData() {
    this.http
      .httpGetWithHeaderAndId("getSubscribeById", this.subscriptionId)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.subscriptionData = res.data;          
          this.createAdForm.patchValue(res.data);
          this.createAdForm.controls['created_at'].patchValue(res['data']['created_at'].split('T')[0]) 
        }
      });
  }


  submit() {
    this.submitted = true;
    if (!this.createAdForm.valid) {
      return true;
    }
    if (this.createAdForm.valid) {
      let payload = this.createAdForm.value;
      payload.users = [];
      payload.total_revenue = 0
      var formData = new FormData();
      var datePipe = new DatePipe("en-US");
      let created_at = datePipe.transform(this.createAdForm.value.created_at, "yyyy-MM-ddTHH:mm:ss");
      payload.created_at = created_at;
      formData.append("created_at", created_at)
      if(!this.subscriptionId){
        this.http.httpPostWithHeaderAndBody("addSubscription", payload)
        .subscribe(
          (response: any) => {
            if (response.code == 200) {
              this.commonService.presentsToast('success', "Subscription Added Successfully");
              this.subscriptionId = response.data.id;
              console.log(this.subscriptionId);
            }
          },
          (error) => {
            this.commonService.presentsToast('error', error);
          }
        );
      } else {
        this.http.httpPutWithDataHeaderAndBodyWithid("editSubscription", this.subscriptionId, payload)
        .subscribe(
          (response: any) => {
            if (response.code == 200) {
              this.commonService.presentsToast('success', "Subscription Updated Successfully");
              this.subscriptionId = response.data.id;
              console.log(this.subscriptionId);
            }
          },
          (error) => {
            this.commonService.presentsToast('error', error);
          }
        );
      }
  
    }
  }
  
  

  restrictonView(event) {
    if (this.type === "view") {
      event.stopPropagation();
    }
  }


  onSelectionUser(event){
    console.log(event,'eee');
    this.storeMultipleUserIds = event;
    console.log(this.storeMultipleUserIds,'))))))');

  }


  getcompanyData() {
    this.http
      .httpGetWithHeaderOnly("getCompany")
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.companyData = res.data;          
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getListUser(this.pageNo * this.length);
  }
  
  getListUser(pageNo: any) {
    let page = pageNo - this.length;
    let param = {
      draw: 2,
      columns: [
        {
          data: "job_title",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "first_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "organization_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "id",
          name: "",
          searchable: false,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 3,
          dir: "undefined",
        },
      ],
      start: page,
      length: this.length,
      id:+this.subscriptionId,
      search: {
        // value: search,
        regex: false,
      },
    };
    this.http
      .httpPostWithHeaderAndBody("getAllUserviaSubscription", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          console.log(res.data);
          this.allUsersList = res.data;
          console.log(this.allUsersList,'***');
          this.totalItem = res.recordsTotal;
        } else {
        }
      });
  }


  toggle(event, id) {
    console.log(event.checked,id)
    // this.id = number;
    var param;
    if (event.checked == false) {
      param = {
        is_subscribed: 'false',
        user_id :id
      };
    } else {
      param = {
        is_subscribed: 'true',
        user_id :id
      };
    }
    console.log(param,'ppp');
    this.http
      .httpPostWithFormDataHeaderAndBody("enableDisableSubscriberViaUser", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.commonService.presentsToast(
            "success",
            "Status Changed successfully"
          );
        }
      });
  }

  // submitSearch() {
  //   if (this.searchForm.get("search").value !== "") {
  //     this.pageNo = 1;
  //     this.getListUser(
  //       this.pageNo * this.length,
  //       this.searchForm.get("search").value
  //     );
  //   }
  // }

  autoResize(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';  // Reset the height to auto
    textarea.style.height = `${textarea.scrollHeight}px`;  // Set the height based on scrollHeight
  }
  addUserRoute(){

  }

}
