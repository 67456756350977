import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { apiList } from "src/app/services/api-list";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  baseUrl: any = environment.baseUrl;
  constructor(private http: HttpClient, private apiList: apiList) {}

  httpPostWithoutHeader(url: any, param: any) {
    return this.http.post(this.baseUrl + this.apiList[url], param);
  }
  httpPutWithoutHeader(url: any, id: any, param: any) {
    return this.http.put(this.baseUrl + this.apiList[url] + id + "/", param);
  }
  httpGetWithoutHeaderWithParam(url: any, param: any, formData: any) {
    var Q = param ? "/" : "";
    return this.http.get(
      this.baseUrl + this.apiList[url] + param + "/",
      formData
    );
  }
  httpPostWithHeaderAndBody(url: any, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      param,
      this.header()
    );
  }

  httpPostWithHeaderAndBodyWithId(url: any,id:any, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url] + id + '/',
      param,
      this.header()
    );
  }

  httpPostWithFormDataHeaderAndBody(url: any, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      param,
      this.formDataHeader()
    );
  }

  httpPutWithFormDataHeaderAndBodyWithid(url: any, id: any, param: any) {
    return this.http.put(
      this.baseUrl + this.apiList[url] + id + "/",
      param,
      this.formDataHeader()
    );
  }

  httpPutWithDataHeaderAndBodyWithid(url: any, id: any, param: any) {
    return this.http.put(
      this.baseUrl + this.apiList[url] + id + "/",
      param,
      this.header()
    );
  }

  httpPutWithDataHeaderAndBody(url: any, param: any) {
    return this.http.put(
      this.baseUrl + this.apiList[url],
      param,
      this.header()
    );
  }

  httpGetWithHeaderAndId(url: any, id: any) {
    return this.http.get(
      this.baseUrl + this.apiList[url] + id + "/",
      this.header()
    );
  }

  httpGetWithHeaderOnly(url: any) {
    return this.http.get(
      this.baseUrl + this.apiList[url],
      this.header()
    );
  }

  httpDeleteHeader(url: string, params: any) {
    let q = params ? "/" : "";
    return this.http.delete(
      this.baseUrl + this.apiList[url] + params + "/",
      this.header()
    );
  }

  header() {
    if (
      localStorage.Authorization != undefined ||
      localStorage.Authorization != null
    ) {
      const headers = new HttpHeaders({
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.Authorization,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  formDataHeader() {
    if (
      localStorage.Authorization != undefined ||
      localStorage.Authorization != null
    ) {
      const headers = new HttpHeaders({
        Authorization: "Bearer " + localStorage.Authorization,
      });
      const option = {
        headers,
      };
      return option;
    }
  }
}
