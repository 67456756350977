import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
})
export class ForgotComponent implements OnInit {
  email: FormControl = new FormControl("", [
    Validators.required,
    Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"),
  ]);
  submmited: boolean = false;
  submit_success = false;

  constructor(
    private http: HttpService,
    private router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit() {}

  submit() {
    this.submmited = true;
    if (this.submmited && this.email.valid) {
      this.submit_success = true;
      let formData: any = new FormData();
      formData.append("email", this.email.value.trim());
      this.http.httpPostWithoutHeader("forgotPassword", formData).subscribe(
        (res: any) => {
          if (res.code == 200) {
            this.commonService.presentsToast(
              "success",
              "Please check your email"
            );
            this.submmited = false;
            this.submit_success = false;
            this.email.reset();
          } else {
            console.log(res);
            this.commonService.presentsToast("error", res.data.email[0]);
            this.submit_success = false;
          }
        },
        (err) => {
          console.log(err);
          this.submit_success = false;
          this.commonService.presentsToast("error", err.statusText);
        }
      );
    }
  }
}
