import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
declare var $: any;
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  image_data;
  imgBaseUrl = environment.imageUrl;
  permission_data: any;
  id:any
  role_id;
  route_url: string;
  constructor(
    public router: Router,
    private http: HttpService,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {
    this.getUserDetails();
    this.route_url = this.router.url;
    this.id = this.route.snapshot.paramMap.get('id');
     console.log(this.id);
    console.log(this.router.url);
  }

  ngOnInit() {
    this.commonService.isProfileUpdated.subscribe((res: boolean) => {
      if (res) {
        this.getUserDetails();
      }
    });
    $(document).ready(function () {
      $("#sidebar__toggle").on("click", function () {
        $("#sidebar-mini").toggleClass("sidenav-toggled");
        if (document.body.classList.contains("sidenav-toggled")) {
          document.body.classList.remove("sidenav-toggled");
        } else {
          document.body.classList.add("sidenav-toggled");
        }
      });
    });

    this.role_id = localStorage.getItem("roleId");
    if (this.role_id == "5") {
      this.permission_data = JSON.parse(
        localStorage.getItem("user_permission")
      );
    }
  }

  getUserDetails() {
    this.http.httpGetWithHeaderOnly("getUserDetails").subscribe((res: any) => {
      if (res.code == 200) {
        console.log(res);

        this.image_data = res.data.profile_image_url;
      }
    });
  }

  signOut() {
    localStorage.clear();
    this.router.navigateByUrl("/auth/login");
  }
}
