import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  uploadcsv$:Subject<boolean> = new Subject<boolean>();
  zoneData = [];
  geofenceData = [];
  selectedZoneID: string;
  selectedGeofenceId: string;
  backToListUrl = "/taxi/admin";
  isProfileUpdated: Subject<boolean> = new Subject();

  constructor() {}

  presentsToast(type: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  confirmToast() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  openDialog(type, message) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: type,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
    });
  }
}
