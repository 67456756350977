import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { CommonService } from "../../../services/common.service";
declare var $: any;
import { MustMatch } from "src/app/services/must-match.validators";

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted: any = false;

  constructor( private fb: FormBuilder,
    private http: HttpService,
    private router: Router,
    private commonService: CommonService)
    {
      
    }

  ngOnInit() {
    this.changePasswordForm = this.fb.group(
      $.extend(
        {},
        {
        current_password: new FormControl('', Validators.required),
        new_password: new FormControl('', [Validators.required,Validators.minLength(8)]),
        confirm_password: new FormControl('', Validators.required),
      }
    ),
    $.extend(
      {},
      {
        validator: MustMatch("new_password", "confirm_password"),
      }
    ));
  }

  submit() {
    this.submitted = true;
    if (this.submitted && this.changePasswordForm.valid) {
      let params = {
        current_password:this.changePasswordForm.value.current_password,
        new_password: this.changePasswordForm.value.new_password
      }
      this.http
        .httpPutWithDataHeaderAndBody("changePassword", params)
        .subscribe((res: any) => {
          if (res.code == 200) {
            this.commonService.presentsToast('success', "Password Change successfully.");
            this.router.navigateByUrl("/superadmin/dashboard");
          }
          else{
            this.commonService.presentsToast('error', "Old password is wrong");
          }
        });
    }
  }

  cancel(){
    this.router.navigateByUrl("/superadmin/dashboard");
  }
  
}
