import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-reachcontact",
  templateUrl: "./reachcontact.component.html",
  styleUrls: ["./reachcontact.component.scss"],
})
export class ReachcontactComponent implements OnInit {
  userId: string;
  detailForm: FormGroup;

  constructor(
    private https: HttpService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.userId = this.route.snapshot.paramMap.get("Id");
  }

  ngOnInit() {
    this.detailForm = this.fb.group({
      name: [""],
      financial_institutions: [""],
      email: [""],
      phone_no: [""],
      amount: [""],
      comments: [""],
    });
    this.getData();
  }

  getData() {
    this.https
      .httpGetWithHeaderAndId("getReachOutToBuyDetails", this.userId)
      .subscribe((response: any) => {
        if (response.code === 200) {
          this.detailForm.patchValue(response.data);
        }
      });
  }

  goBack() {
    history.back();
  }
}
