import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reach-filter',
  templateUrl: './reach-filter.component.html',
  styleUrls: ['./reach-filter.component.scss']
})
export class ReachFilterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
