import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[TwoDecimalPoint]'
})
export class TwoDecimalPointDirective {

  private regex = new RegExp(/^\d*\.?\d{0,2}$/g);  // Ensures only up to 2 decimal places
  private specialKeys: Array<string> = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow special keys such as Backspace, Delete, Arrow keys, etc.
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' || event.key === '.' ? '.' : event.key,
      current.slice(position)
    ].join('');

    // If there's already a decimal point, make sure only 2 digits are allowed after it
    const parts = next.split('.');
    if (parts[1] && parts[1].length > 2) {
      event.preventDefault(); // Block input if more than 2 decimals
      return;
    }

    // Prevent input if the next value doesn't match the regex pattern
    if (next && !this.regex.test(next)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedInput = clipboardData.getData('text');
    const next = this.el.nativeElement.value + pastedInput;
    
    // Prevent pasting if it would break the 2 decimal rule
    const parts = next.split('.');
    if (parts[1] && parts[1].length > 2) {
      event.preventDefault();
    }

    if (pastedInput && !this.regex.test(pastedInput)) {
      event.preventDefault();
    }
  }
}


