import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import Swal from "sweetalert2";
import { CommonService } from "../../../../services/common.service";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-addrequest',
  templateUrl: './addrequest.component.html',
  styleUrls: ['./addrequest.component.scss']
})
export class AddrequestComponent implements OnInit {
  newadsData = [];
  pageNo: any = 1;
  search: any;
  totalItem: any;
  isnewadsSuccess:boolean = false;
  searchForm : FormGroup;
  length= 10;

  constructor(public router: Router,private commonService: CommonService,private http: HttpService,private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      search : ['']
    })
   }

  ngOnInit() {
    this.getNewAdsList(this.pageNo * this.length, this.searchForm.get("search").value);
  }

  getNewAdsList(pageNo: any, search: any) {
    this.isnewadsSuccess = false;
    this.newadsData = [];
    let StartPage = pageNo - this.length;
    console.log(StartPage);
    
    var param = {
      draw: 2,
      columns: [
        {
          data: "id",
          name: "",
          searchable: false,
          orderable: true,
          search: {
              value: "",
              regex: false
          }
        }
      ],
      order: [
        {
          column: 0,
          dir: "undefined"
        }
      ],
      start: StartPage,
      length: this.length,
      search: {
        value: this.searchForm.get("search").value,
        regex: false
      }
      
    };
    

    this.http.httpPostWithHeaderAndBody("getnewadvertisementswithpagination", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.newadsData = res['data'];
          console.log(this.newadsData);
          this.totalItem = res.recordsTotal;
          this.isnewadsSuccess = true;         
        } else if (res.code == 204) {
          this.newadsData = [];
          this.isnewadsSuccess = true;
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getNewAdsList(this.pageNo * this.length, this.searchForm.get("search").value);
  }

  submitSearch() {
    if (this.search.value !== "") {
      this.pageNo = 1;
      this.getNewAdsList(this.pageNo * this.length, this.searchForm.get("search").value);
    }
  }

  accept(id){
   var param1 = {
      status: 1
    }
    this.http.httpPutWithDataHeaderAndBodyWithid("updateAdvertisementStatus", id, param1).subscribe((res:any)=>{
      if(res.code == 200) {
        this.router.navigate(["superadmin/advertise"]);
        this.commonService.presentsToast('success', "Advertisement updated!");
      }
    })
  }

  reject(id){
    var param1 = {
      status: 0
    }
    this.http.httpPutWithDataHeaderAndBodyWithid("updateAdvertisementStatus", id, param1).subscribe((res:any)=>{
      if(res.code == 200) {
        this.getNewAdsList(this.pageNo * this.length, this.searchForm.get("search").value);
        this.commonService.presentsToast('success', "Advertisement updated!");
      }
    })
  }

  deleteNewads(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteHeader("deletAdvertisements", id).subscribe((res: any) => {
          Swal.fire("Deleted!", "Advertisement has been deleted.", "success");       
          this.getNewAdsList(this.pageNo * length, this.searchForm.get("search").value);    
        });
      }
    });
  }

  onKeyUp(event){
    this.search= event
    this.pageNo = 1;
    this.getNewAdsList(this.pageNo * this.length, this.searchForm.get("search").value);
    
  }

}
