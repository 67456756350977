

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Location } from '@angular/common'
@Component({
  selector: 'app-addapplyjob',
  templateUrl: './addapplyjob.component.html',
  styleUrls: ['./addapplyjob.component.scss']
})
export class AddapplyjobComponent implements OnInit {
  routeId: any;
  type: any
  userId: string;
  arr = [];
  profile_data: any;
  education_data: any;
  work_data: any;

  constructor(private route: ActivatedRoute, private http: HttpService, private location: Location) {
    this.userId = this.route.snapshot.paramMap.get('id');      
  }

  ngOnInit() {
    this.getapplicantDetails();
  }

  getapplicantDetails(){
    this.http.httpGetWithHeaderAndId("getProfileDetailsByUserId", this.userId).subscribe((res:any)=>{
      if(res.code == 200){        
        this.profile_data = res.data;
        this.education_data = res.data.education_info[0];
        this.work_data = res.data.work_experience[0];
      }
    })
  }

  back(){
    this.location.back();
  }  
}
  
