import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-participationdetail',
  templateUrl: './participationdetail.component.html',
  styleUrls: ['./participationdetail.component.scss']
})
export class ParticipationdetailComponent implements OnInit {

  arr = [];
 arr2 = [];
  constructor(public router: Router) { }

  ngOnInit() {
    this.arr = [
      { name: 'john',amount: '$50,000',loanPurpose:'Lorem ipsum, or lipsum as it is sometimes known,',collateralType:'Gold', particiapationAmout: '$50,000', particiapationPer:'50%', status:'Pending',},

    ];
    this.arr2 = [
      {purpose:'Lorem ipsum, or lipsum as it is sometimes known', terms:' Lorem ipsum, or lipsum as it is sometimes known', name: 'john',},

    ];
  }

}

