import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { CommonService } from "../../../services/common.service";

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  public model = {
    CONTACT_SUPPORT: '',
    LEGAL: '',
    PRIVACY_POLICY: '',
    FAQS: '',
    OUR_MISSION: '',
    EASY_TO_USE: '',
    MEMBERSHIP: '',
    FINCO_APP: '',
    WHERE_DOES_IT_COME_FROM: '',
    GET_START: '',
    OUR_PRICING_PLANS: ''
  };
  public terms_conditon: string = "";
  public privacy_policy: string = "";
  public about_us: string = "";
  public cookie_policy:string ="";
  isSuccess: boolean = false;
  CMSDetails: any;

  constructor(private http: HttpService,
    private commonService: CommonService) { }

  ngOnInit() {
    this.getAllSectorLists();
  }


  getAllSectorLists() {
    this.http.httpGetWithHeaderOnly("getCSMDetails").subscribe((res: any) => {
      if (res.code == 200) {
        this.CMSDetails = res.data;
        this.terms_conditon = this.CMSDetails['terms_conditon'];
        this.privacy_policy = this.CMSDetails['privacy_policy'];
        this.about_us = this.CMSDetails['about_us'];
        this.cookie_policy = this.CMSDetails['cookie_policy'];
        this.isSuccess = true;
      }
    })
  }

  public onChangeTerms({ editor }: any) {
    const data = editor.getData();
    this.terms_conditon = data;
  }

  public onChangePolicy({ editor }: any) {
    const data = editor.getData();
    this.privacy_policy = data;
  }

  public onChangeAboutUs({ editor }: any) {
    const data = editor.getData();
    this.about_us = data;
  }

  public onChangeCookie({ editor }: any) {
    const data = editor.getData();
    this.cookie_policy = data;
  }

  updateCSMDetails() {
    var param = {
      "terms_conditon": this.terms_conditon,
      "privacy_policy": this.privacy_policy,
      "about_us": this.about_us,
      "cookie_policy":this.cookie_policy
    }
    this.http.httpPostWithHeaderAndBody("createUpdateCms", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.commonService.presentsToast('success', res.message);
        }
      });
  }
}
