import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import * as FileSaver from "file-saver";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  routeId: any;
  type: any;
  userId: string;
  userForm: FormGroup;
  fileData: File;
  imageUrl: string = "";
  submitted: boolean = false;
  formattedaddress = " ";
  jobData: any;
  longitude: any;
  totalItem: any;
  latitude: any;
  companyData: any[] = [];
  fileId: any;
  length = 10;
  pageNo: any = 1;
  subscriptionId:any;
  subscriptionData: any;
  allUsersList: any = [];
  userData:any = [];
  todayDate: any;
  storeCompanyId:any
  userList:any[] = [];
  storeMultipleUserIds:any[] = [];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private https: HttpService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.userForm = this.fb.group({
      email: new FormControl("", [Validators.required,Validators.maxLength(100)]),
    });
    this.subscriptionId = this.route.snapshot.paramMap.get("id");

    
  }

  ngOnInit() {
    if(this.subscriptionId){
      this.getSusbcriptionData()
    }
  }


  getSusbcriptionData() {
    this.https
      .httpGetWithHeaderAndId("getSubscribeById", this.subscriptionId)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.subscriptionData = res.data.bussiness_name;
          console.log(this.subscriptionData,'ss');       
        }
      });
  }


  submit() {
    this.userForm.patchValue({
      email: this.userForm.get('email').value.trim()
    })
    this.submitted = true;
    if (!this.userForm.valid) {
      return true;
    }
    if(this.userForm.valid){
      let payload = this.userForm.value;
      payload.subscription_id = this.subscriptionId;
      this.https
        .httpPostWithFormDataHeaderAndBody('addEmailSubscription', payload)
        .subscribe(
          (response: any) => {
            if (response.code == 200) {
              this.getUser(this.pageNo * this.length)
              this.userForm.reset();
              this.submitted = false;
            } else {
              this.commonService.presentsToast('error', response.message);
            }
          },
          (error) => {
            this.commonService.presentsToast('error', error);
          }
        );

  }
}

getPage(pageNo: number) {
  this.pageNo = pageNo;
  this.getUser(this.pageNo * this.length);
}

getUser(pageNo: any) {
  this.allUsersList = [];
  let page = pageNo - this.length;
  let param = {
    draw: 2,
    columns: [
      {
        data: "job_title",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "first_name",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "organization_name",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "id",
        name: "",
        searchable: false,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
    ],
    order: [
      {
        column: 3,
        dir: "undefined",
      },
    ],
    start: page,
    length: this.length,
    id:this.subscriptionId,
    search: {
      regex: false,
    },
  };
  this.https
    .httpPostWithHeaderAndBody("getAllUserviaSubscription", param)
    .subscribe((res: any) => {
      if (res.code == 200) {
        console.log(res.data);
        this.allUsersList = res.data;
        this.totalItem = res.recordsTotal;
      } else {
      }
    });
}
download(type) {
  let page = 0;
  let param = {
    draw: 2,
    columns: [
      {
        data: "job_title",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "first_name",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "organization_name",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "id",
        name: "",
        searchable: false,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
    ],
    order: [
      {
        column: 3,
        dir: "undefined",
      },
    ],
    start: page,
    length: 1152,
    search: {
      value: '',
      regex: false,
    },
  };


  this.https
    .httpPostWithHeaderAndBody("getAllUsersBySectorId", param)
    .subscribe((res: any) => {
      if (res.code == 200) {
        const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
        const header = Object.keys(res["data"][0]);
        let csv = res["data"].map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        );
        csv.unshift(header.join(","));
        let csvArray = csv.join("\r\n");
        const contentType = "text/csv";
        const blob = new Blob([csvArray], { type: contentType });
        FileSaver.saveAs(blob, "Users." + type);
      }
    });
}
back(){
  history.back();
}

}
