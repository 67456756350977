import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-access",
  templateUrl: "./access.component.html",
  styleUrls: ["./access.component.scss"],
})
export class AccessComponent implements OnInit {
  allRolesList: any[] = [];
  pageNo: any = 1;
  search: any;
  totalItem: any;
  showTable: boolean = false;
  searchForm: FormGroup;

  constructor(
    public router: Router,
    private http: HttpService,
    private commonService: CommonService,
    private fb: FormBuilder
  ) {
    this.searchForm = this.fb.group({
      search: [""],
    });
  }

  ngOnInit() {
    this.getAllRoles(this.pageNo * 10, this.searchForm.get("search").value);
  }

  getAllRoles(pageNo: any, search: any) {
    this.allRolesList = [];
    let StartPage = pageNo - 10;
    var param = {
      draw: 2,
      columns: [
        {
          data: "user__first_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "undefined",
        },
      ],
      start: StartPage,
      length: 10,
      search: {
        value: search,
        regex: false,
      },
    };

    this.http
      .httpPostWithHeaderAndBody("getAllPermissionListWithPagination", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.allRolesList = res.data;
          this.totalItem = res.recordsTotal;
          this.showTable = true;
        } else if (res.code == 204) {
          this.allRolesList = [];
          this.showTable = true;
        } else {
          this.showTable = true;
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getAllRoles(this.pageNo * 10, this.searchForm.get("search").value);
  }

  submitSearch() {
    if (this.searchForm.get("search").value !== "") {
      this.pageNo = 1;
      this.getAllRoles(this.pageNo * 10, this.searchForm.get("search").value);
    }
  }

  onKeyUp(event) {
    this.search = event;
    this.pageNo = 1;
    this.getAllRoles(this.pageNo * 10, this.searchForm.get("search").value);
  }

  updatePermission(data) {
    let param = {
      id: data.id,
      user: "",
      is_user: data.is_user == true ? 1 : 0,
      is_participants: data.is_participants == true ? 1 : 0,
      is_jobs: data.is_jobs == true ? 1 : 0,
      is_advertise: data.is_advertise == true ? 1 : 0,
      is_cms: data.is_cms == true ? 1 : 0,
      is_role: data.is_role == true ? 1 : 0,
      is_analytics: data.is_analytics == true ? 1 : 0,
    };
    this.http
      .httpPostWithHeaderAndBody("createUpdateRolePermission", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.commonService.presentsToast(
            "success",
            "Roles/Access updated successfully"
          );
        }
      });
  }
}
