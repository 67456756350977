import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccessComponent } from "./pages/access/access.component";
import { AddAccessComponent } from "./pages/access/add-access/add-access.component";
import { AddadvertiseComponent } from "./pages/advertise/addadvertise/addadvertise.component";
import { AddrequestComponent } from "./pages/advertise/addrequest/addrequest.component";
import { AdvertiseComponent } from "./pages/advertise/advertise.component";
import { AnalyticsComponent } from "./pages/analytics/analytics.component";
import { CalenderComponent } from "./pages/calender/calender.component";
import { ChangepasswordComponent } from "./pages/changepassword/changepassword.component";
import { CmsComponent } from "./pages/cms/cms.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AddjobComponent } from "./pages/jobs/addjob/addjob.component";
import { AddapplyjobComponent } from "./pages/jobs/applyjob/addapplyjob/addapplyjob.component";
import { ApplyjobComponent } from "./pages/jobs/applyjob/applyjob.component";
import { JobsComponent } from "./pages/jobs/jobs.component";
import { AddparticiapantsComponent } from "./pages/participants/addparticiapants/addparticiapants.component";
import { ParticipantsComponent } from "./pages/participants/participants.component";
import { ParticipationdetailComponent } from "./pages/participants/participationdetail/participationdetail.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { AdduserComponent } from "./pages/users/adduser/adduser.component";
import { UsersComponent } from "./pages/users/users.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SuperadminComponent } from "./superadmin.component";
import { CampainesComponent } from "./pages/campaines/campaines.component";
import { AuthGuard } from "../services/auth.guard";
import { ViewuserComponent } from "./pages/users/viewuser/viewuser.component";
import { GroupchatComponent } from "./pages/groupchat/groupchat.component";
import { GroupinfoComponent } from "./pages/groupinfo/groupinfo.component";
import { ReachOutComponent } from "./pages/reach-out/reach-out.component";
import { ReachoutBuyComponent } from "./pages/reachout-buy/reachout-buy.component";
import { ReachLoanComponent } from "./pages/reach-loan/reach-loan.component";
import { ReachFilterComponent } from "./pages/reach-filter/reach-filter.component";
import { ReachcontactComponent } from "./pages/reachcontact/reachcontact.component";
import { PreLeadComponent } from "./pages/pre-lead/pre-lead.component";
import { EditLeadComponent } from "./pages/pre-lead/edit-lead/edit-lead.component";
import { SubscriptionComponent } from "./pages/subscription/subscription.component";
import { AddSubscriptionComponent } from "./pages/subscription/add-subscription/add-subscription.component";
import { AddUserComponent } from "./pages/subscription/add-user/add-user.component";

const routes: Routes = [
  { path: "", component: DashboardComponent, canActivate: [AuthGuard] },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "sidebar", component: SidebarComponent, canActivate: [AuthGuard] },
  { path: "users", component: UsersComponent, canActivate: [AuthGuard] },
  { path: "user/:type", component: AdduserComponent, canActivate: [AuthGuard] },
  {
    path: "user/view/:id",
    component: ViewuserComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "user/:type/:id",
    component: AdduserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "lead/edit/:id",
    component: EditLeadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "participants",
    component: ParticipantsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "groupchat",
    component: GroupchatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "groupinfo/:type/:id",
    component: GroupinfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "participants/:type",
    component: AddparticiapantsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "participants/:type/:id",
    component: AddparticiapantsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "participationdetail",
    component: ParticipationdetailComponent,
    canActivate: [AuthGuard],
  },
  { path: "jobs", component: JobsComponent, canActivate: [AuthGuard] },
  { path: "jobs/:type", component: AddjobComponent, canActivate: [AuthGuard] },
  {
    path: "jobs/:type/:id",
    component: AddjobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "applyjob/:id",
    component: ApplyjobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "applyjob/view/:id",
    component: AddapplyjobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "advertise",
    component: AdvertiseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "addrequest",
    component: AddrequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "advertise/:type",
    component: AddadvertiseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "advertise/:type/:id",
    component: AddadvertiseComponent,
    canActivate: [AuthGuard],
  },
  { path: "cms", component: CmsComponent, canActivate: [AuthGuard] },
  {
    path: "analytics",
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
  },
  { path: "access", component: AccessComponent, canActivate: [AuthGuard] },
  {
    path: "access/add",
    component: AddAccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "calender",
    component: CalenderComponent,
    canActivate: [AuthGuard],
  },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: "changepassword",
    component: ChangepasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaines",
    component: CampainesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reachout/:Id",
    component: ReachOutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reachbuy",
    component: ReachoutBuyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reachloan/:id",
    component: ReachLoanComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reachfilter",
    component: ReachFilterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reachcontact/:Id",
    component: ReachcontactComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "real-state-lead",
    component: PreLeadComponent,
    canActivate: [AuthGuard],
  },
  { path: "subscription", component: SubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/:type", component: AddSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/:type/:id", component: AddSubscriptionComponent, canActivate: [AuthGuard] },
  { path: "subscription/:type/addUser/:id", component: AddUserComponent, canActivate: [AuthGuard] },


  // {
  //   path: "user/:type/:id",
  //   component: AdduserComponent,
  //   canActivate: [AuthGuard],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperadminRoutingModule {}
