import { Input, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[NoSpaceAtFirst]',
})
export class appNoSpaceAtFirstDirective {
  @Input() isAlphaNumeric!: boolean;

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent):void {
    if (this.el.nativeElement.selectionStart === 0 && event.key === ' ') {
      event.preventDefault();
    }
  }
}
