import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SuperadminRoutingModule } from "./superadmin-routing.module";
import { SuperadminComponent } from "./superadmin.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { UsersComponent } from "./pages/users/users.component";
import { AdduserComponent } from "./pages/users/adduser/adduser.component";
import { MaterialModule } from "../material/material.module";
import { ParticipantsComponent } from "./pages/participants/participants.component";
import { ParticipationdetailComponent } from "./pages/participants/participationdetail/participationdetail.component";
import { JobsComponent } from "./pages/jobs/jobs.component";
import { AddparticiapantsComponent } from "./pages/participants/addparticiapants/addparticiapants.component";
import { AddjobComponent } from "./pages/jobs/addjob/addjob.component";
import { ApplyjobComponent } from "./pages/jobs/applyjob/applyjob.component";
import { AddapplyjobComponent } from "./pages/jobs/applyjob/addapplyjob/addapplyjob.component";
import { AdvertiseComponent } from "./pages/advertise/advertise.component";
import { CmsComponent } from "./pages/cms/cms.component";
import { CKEditorModule } from 'ckeditor4-angular';
import { AnalyticsComponent } from "./pages/analytics/analytics.component";
import { AccessComponent } from "./pages/access/access.component";
import { CalenderComponent } from "./pages/calender/calender.component";

import { DropDownButtonAllModule } from "@syncfusion/ej2-angular-splitbuttons";
import { TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import {
  DropDownListAllModule,
  MultiSelectAllModule,
} from "@syncfusion/ej2-angular-dropdowns";
import {
  MaskedTextBoxModule,
  UploaderAllModule,
} from "@syncfusion/ej2-angular-inputs";
import {
  ToolbarAllModule,
  ContextMenuAllModule,
} from "@syncfusion/ej2-angular-navigations";
import {
  ButtonAllModule,
  CheckBoxAllModule,
  SwitchAllModule,
} from "@syncfusion/ej2-angular-buttons";
import {
  DatePickerAllModule,
  TimePickerAllModule,
  DateTimePickerAllModule,
} from "@syncfusion/ej2-angular-calendars";
import {
  NumericTextBoxAllModule,
  TextBoxAllModule,
} from "@syncfusion/ej2-angular-inputs";
import {
  ScheduleAllModule,
  RecurrenceEditorAllModule,
} from "@syncfusion/ej2-angular-schedule";
import { AddadvertiseComponent } from "./pages/advertise/addadvertise/addadvertise.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ChangepasswordComponent } from "./pages/changepassword/changepassword.component";
import { AddrequestComponent } from "./pages/advertise/addrequest/addrequest.component";
import { CampainesComponent } from "./pages/campaines/campaines.component";
import { ResetPasswordComponent } from "../auth/reset-password/reset-password.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "../services/interceptor.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiList } from "../services/api-list";
import { LocalStorageService } from "angular-web-storage";
import { HttpService } from "../services/http.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxPaginationModule } from "ngx-pagination";
import { AddAccessComponent } from './pages/access/add-access/add-access.component';
import { MyChartComponent } from './pages/my-chart/my-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ViewuserComponent } from './pages/users/viewuser/viewuser.component';
import { MAT_DATE_LOCALE, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { GroupchatComponent } from './pages/groupchat/groupchat.component';
import { GroupinfoComponent } from './pages/groupinfo/groupinfo.component';
import { ReachOutComponent } from './pages/reach-out/reach-out.component';
import { ReachoutBuyComponent } from './pages/reachout-buy/reachout-buy.component';
import { ReachLoanComponent } from './pages/reach-loan/reach-loan.component';
import { ReachFilterComponent } from './pages/reach-filter/reach-filter.component';
import { ReachcontactComponent } from './pages/reachcontact/reachcontact.component';
import { PreLeadComponent } from './pages/pre-lead/pre-lead.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AddLeadComponent } from './pages/pre-lead/add-lead/add-lead.component';
import { EditLeadComponent } from './pages/pre-lead/edit-lead/edit-lead.component';
import { AddSubscriptionComponent } from './pages/subscription/add-subscription/add-subscription.component';
import { SubscriptionComponent } from "./pages/subscription/subscription.component";
import { AddUserComponent } from './pages/subscription/add-user/add-user.component';
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";



@NgModule({
  declarations: [
    SuperadminComponent,
    DashboardComponent,
    SidebarComponent,
    UsersComponent,
    AdduserComponent,
    ParticipantsComponent,
    ParticipationdetailComponent,
    JobsComponent,
    AddparticiapantsComponent,
    AddjobComponent,
    ApplyjobComponent,
    AddapplyjobComponent,
    AdvertiseComponent,
    CmsComponent,
    AnalyticsComponent,
    AccessComponent,
    CalenderComponent,
    AddadvertiseComponent,
    ProfileComponent,
    ChangepasswordComponent,
    AddrequestComponent,
    CampainesComponent,
    AddAccessComponent,
    MyChartComponent,
    ViewuserComponent,
    GroupchatComponent,
    GroupinfoComponent,
    ReachOutComponent,
    ReachoutBuyComponent,
    ReachLoanComponent,
    ReachFilterComponent,
    ReachcontactComponent,
    PreLeadComponent,
    AddLeadComponent,
    EditLeadComponent,
    SubscriptionComponent,
    AddSubscriptionComponent,
    AddUserComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    SuperadminRoutingModule,
    MaterialModule,
    CKEditorModule,
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    NumericTextBoxAllModule,
    TextBoxAllModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    CheckBoxAllModule,
    ToolbarAllModule,
    DropDownListAllModule,
    ContextMenuAllModule,
    MaskedTextBoxModule,
    UploaderAllModule,
    MultiSelectAllModule,
    TreeViewModule,
    ButtonAllModule,
    DropDownButtonAllModule,
    SwitchAllModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    NgxPaginationModule,
    NgxEchartsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SharedModule
    
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
  ],
  entryComponents:[AddLeadComponent]
})
export class SuperadminModule {}
