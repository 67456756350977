import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-reach-out",
  templateUrl: "./reach-out.component.html",
  styleUrls: ["./reach-out.component.scss"],
})
export class ReachOutComponent implements OnInit {
  Id: string;
  userList: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService
  ) {
    this.Id = this.route.snapshot.paramMap.get("Id");
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.http
      .httpGetWithHeaderAndId("getReachOutToBuyList", this.Id)
      .subscribe((res: any) => {
        if (res.code === 200) {
          this.userList = res.data;
        }
      });
  }

  goBack() {
    history.back();
  }
}
