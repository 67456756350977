import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { runInThisContext } from 'vm';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AdduserComponent implements OnInit {
  routeId: any;
  type: any
  userId: string;
  arr = [];
  editUserDetailsForm : FormGroup;
  educationalDetailsForm : FormGroup;
  workExperienceForm : FormGroup;
  allSectorList:any[] = [];
  formattedaddress = " ";
  longitude: any;
  latitude: any;
  fileData: File;
  imageUrl: string = "";
  coverFileData:File;
  coverImageUrl : string = "";
  submitted : boolean = false;
  submitEducationalDetail : boolean = false;
  submitWorkExperience : boolean = false;
  baseUrl = environment.imageUrl;
  newsFeed:any;
  adsData = [];
  pageNo: any = 1;
  search: FormControl = new FormControl("");
  totalItem: any;
  isadsSuccess:boolean = false;
  educationalDetails: any[] =[];
  education_id: string;
  workDetails: any[] = [];
  work_id: string;
  followers:string;
  following: string;

  constructor(private route: ActivatedRoute, private http:HttpService, private fb:FormBuilder,private router:Router, private commonService: CommonService) {
    this.type = this.route.snapshot.paramMap.get('type');
    this.userId = this.route.snapshot.paramMap.get('id');
      
    this.editUserDetailsForm = this.fb.group({
      "first_name" : new FormControl('',Validators.required),
      "last_name": new FormControl('', Validators.required),
      "email" : new FormControl('',Validators.required),
      "phone_no" : new FormControl('',Validators.required),
      "address" : new FormControl('',Validators.required),
      "organisation" : new FormControl('',Validators.required),
      "jobTitle" : new FormControl('',Validators.required),
    });
    this.educationalDetailsForm = this.fb.group({
      "school_name" : new FormControl('', Validators.required),
      "degree_name" : new FormControl('', Validators.required),
      "start_year" : new FormControl('', Validators.required),
      "end_year" : new FormControl('', Validators.required),
    });
    this.workExperienceForm = this.fb.group({
      "current_position" : new FormControl('', Validators.required),
      "title" : new FormControl('', Validators.required),
      "start_year" : new FormControl('', Validators.required),
      "end_year" : new FormControl('', Validators.required),
    })
    
  }

  updateUserWorkExperience()
  {
    this.submitWorkExperience = true;
    if(this.submitWorkExperience && this.workExperienceForm.valid)
    {
      var datePipe = new DatePipe("en-US");
      let requestDate = datePipe.transform(
        this.workExperienceForm.value.start_year,
        "MMM - y"
      );
      let expiryDate = datePipe.transform(
        this.workExperienceForm.value.end_year,
        "MMM - y"
      );
      var param = {
        "title":this.workExperienceForm.value.title.trim(),
        "current_position":this.workExperienceForm.value.current_position.trim(),
        "start_year":requestDate,
        "end_year":expiryDate,
        "user": this.userId
      }

      if(this.workDetails.length === 0){
        
        this.http.httpPostWithHeaderAndBody("createWorkExp",param).subscribe((res:any)=>{
          if(res.code == 200)
          {
            this.commonService.presentsToast("success", "Updated Successfully")
            this.router.navigateByUrl('/superadmin/users')
          }
        }) 
      }
      else{
      
      this.http.httpPutWithDataHeaderAndBodyWithid("updateWorkExperienceById",this.work_id,param).subscribe((res:any)=>{
        if(res.code == 200)
        {
          this.commonService.presentsToast("success", "Updated Successfully")
          this.router.navigateByUrl('/superadmin/users')
        }
      })
    }
  }
  }

  updateUserEducationalDetails()
  {
    this.submitEducationalDetail = true;
    if(this.submitEducationalDetail && this.educationalDetailsForm.valid)
    {
      var datePipe = new DatePipe("en-US");
      let requestDate = datePipe.transform(
        this.educationalDetailsForm.value.start_year,
        "MMM - y"
      );
      console.log(requestDate);
      
      let expiryDate = datePipe.transform(
        this.educationalDetailsForm.value.end_year,
        "MMM - y"
      );
      var param = {
        "school_name":this.educationalDetailsForm.value.school_name.trim(),
        "degree_name":this.educationalDetailsForm.value.degree_name.trim(),
        "start_year":requestDate,
        "end_year":expiryDate,
        "user": this.userId
      }

      if(this.educationalDetails.length  === 0){
        
        this.http.httpPostWithHeaderAndBody("createEducationInfo",param).subscribe((res:any)=>{
          if(res.code == 200)
          {
            this.commonService.presentsToast("success", "Updated Successfully")
            this.router.navigateByUrl('/superadmin/users')
          }
          
        }) 
      }
      else{
      this.http.httpPutWithDataHeaderAndBodyWithid("updateEducationalDetailById",this.education_id,param).subscribe((res:any)=>{
        if(res.code == 200)
        {
          this.commonService.presentsToast("success", "Updated Successfully")
          this.router.navigateByUrl('/superadmin/users')
        }
        
      })
    }
  }
  }

  updateUserDetails()
  {
    this.submitted = true;
    if(this.submitted && this.editUserDetailsForm.valid)
    {
      let formData = new FormData();
      if(this.fileData)
      {
        formData.append('profile_image',this.fileData);
      }
      if(this.coverFileData)
      {
      formData.append('cover_image',this.coverFileData);
      }
      formData.append('first_name',this.editUserDetailsForm.value.first_name);
      formData.append('last_name',this.editUserDetailsForm.value.last_name);
      formData.append('organization_name',this.editUserDetailsForm.value.organisation);
      formData.append('job_title',this.editUserDetailsForm.value.jobTitle);
      formData.append('phone_no',this.editUserDetailsForm.value.phone_no)
      formData.append('address',this.editUserDetailsForm.value.address);
      formData.append('longitude',this.longitude);
      formData.append('latitude',this.latitude);
      this.http.httpPutWithFormDataHeaderAndBodyWithid("updateUserById",this.userId,formData).subscribe((res:any)=>{
        if(res.code == 200)
        {
          this.commonService.presentsToast("success", "Updated Successfully")
          this.router.navigateByUrl('/superadmin/users')
        }
      })
    }
  }

  ngOnInit() {
    this.getAllSectorLists();
    this.getWorkExperience();
    this.getEducationDetails();
    this.getNewsFeed();
    this.getProfileDetails();
    this.arr = [
      { bankName: 'HDFC Bank', loanPurpose:'Lorem ipsum, or lipsum as it is sometimes known,',collateralType:'Gold', particiapationAmout: '$50,000', particiapationPer:'50%', status:'Pending',requestDate:'25-02-2021'},

    ];
    this.getLoanDetails();
  }
  
  getWorkExperience()
  {
    this.http.httpGetWithHeaderAndId("getAllUserWorkExperienceDetailsByUserId",this.userId).subscribe((res:any)=>{
      if(res.code == 200)
      {
        console.log(res.data[0]);
        
        var datePipe = new DatePipe("en-US");
        const startdate = datePipe.transform(res.data[0].start_year, 'yyy-MM-dd');
        const enddate = datePipe.transform(res.data[0].end_year, 'yyyy-MM-dd');
        console.log(startdate);
        console.log(enddate);
        this.workExperienceForm.patchValue({
          "current_position" : res.data[0].current_position,
          "title" : res.data[0].title,
          "start_year" : startdate,
          "end_year" : enddate,
        })

        this.work_id = res.data[0].id;
    }
  })
  }

  getEducationDetails()
  {
    this.http.httpGetWithHeaderAndId("getAllUserEducationDetailsByUserId",this.userId).subscribe((res:any)=>{
      if(res.code == 200)
      {
        var datePipe = new DatePipe("en-US");
        const startdate = datePipe.transform(res.data[0].start_year, 'yyy-MM-dd');
        const enddate = datePipe.transform(res.data[0].end_year, 'yyyy-MM-dd');
        
        
        if(this.educationalDetails){
          this.educationalDetailsForm.patchValue({
            "school_name" : res.data[0].school_name,
            "degree_name" : res.data[0].degree_name,
            "start_year" : startdate,
            "end_year" : enddate,
          })

          this.education_id = res.data[0].id;            
        }
      }
    })
  }

  getNewsFeed()
  {
    this.http.httpGetWithHeaderAndId("getNewsFeedByUserId",this.userId).subscribe((res:any)=>{
      console.log(res);
      if(res.code == 200)
      {
        this.newsFeed = res.data;
      }
      else if(res.code == 204){
        this.newsFeed = [];
      }
    })
  }

  getProfileDetails()
  { 
    this.http.httpGetWithHeaderAndId("getProfileDetailsByUserId",this.userId).subscribe((res:any)=>{
      if(res.code == 200)
      {
        console.log(res.data)
        this.followers = res.data.followers;
        this.following = res.data.following;
        this.editUserDetailsForm.patchValue({
          "first_name" : res.data.first_name,
          "last_name" : res.data.last_name,
          "email" : res.data.email,
          "phone_no" : res.data.phone_no,
          "address" : res.data.address,
          "organisation" : res.data.organization_name,
          "jobTitle" : res.data.job_title
        })

        this.imageUrl = this.baseUrl + res.data.profile_image;
        this.coverImageUrl = this.baseUrl + res.data.cover_image;
        this.longitude = res.data.longitude;
        this.latitude = res.data.latitude
      }
      this.educationalDetails = res.data.education_info;      
      this.workDetails = res.data.work_experience;
    })
  }

  getAllSectorLists()
  {
    this.http.httpGetWithHeaderOnly("getSectorList").subscribe((res:any)=>{
      if(res.code == 200)
      {
        this.allSectorList = res.data;
        console.log(this.allSectorList)
      }
    })
  }

  restrictonView(event) {
    if (this.type === "view") {
      event.stopPropagation();
    }
  }

  onInputChange(event) {
    var locationName: any = "";
    locationName = this.editUserDetailsForm.value.address;
    if (locationName != this.formattedaddress) {
      this.editUserDetailsForm.controls["address"].reset();
    }
  }

  AddressChange(address: any) {
    address.address_components.forEach((res) => {
      if (
        res.types.includes("locality") ||
        res.types.includes("postal_town") ||
        res.types.includes("sublocality") ||
        res.types.includes("sublocality_level_1") ||
        res.types.includes("sublocality_level_2") ||
        res.types.includes("sublocality_level_3") ||
        res.types.includes("sublocality_level_4") ||
        res.types.includes("sublocality_level_5")
      ) {
        this.formattedaddress = res.long_name;
      }
    });
    this.longitude = address.geometry.location.lng();
    this.latitude = address.geometry.location.lat();
    this.editUserDetailsForm.controls["address"].patchValue(this.formattedaddress);
    console.log(this.formattedaddress);
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.fileData = <File>fileInput.target.files[0];      
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = (event) => {
        this.imageUrl = reader.result as string;
      };
    }
  }

  onCoverPhotoChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.coverFileData = <File>fileInput.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = (event) => {
        this.coverImageUrl = reader.result as string;
      };
    }
  }

  getLoanDetails(){
      this.isadsSuccess = false;
      this.adsData = [];
      let StartPage =0;
      var param = {
        draw: 2,
        columns: [
          {
            data: "id",
            name: "",
            searchable: false,
            orderable: true,
            search: {
              value: "",
              regex: false
            }
          }
        ],
        order: [
          {
            column: 0,
            dir: "undefined"
          }
        ],
        start: StartPage,
        length: 10,
        search: {
          value: "",
          regex: false
        }
      };
  
      this.http.httpPostWithHeaderAndBodyWithId("getLoanDetails", this.userId, param)
        .subscribe((res: any) => {
          console.log(res.data);        
          if (res.code == 200) {
            
            this.adsData = res.data;
            this.totalItem = res.recordsTotal;
            this.isadsSuccess = true;         
          } else if (res.code == 204) {
            this.adsData = [];
            this.isadsSuccess = true;
          }
        });
    
  }

}
