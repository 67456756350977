import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-groupchat",
  templateUrl: "./groupchat.component.html",
  styleUrls: ["./groupchat.component.scss"],
})
export class GroupchatComponent implements OnInit {
  sellData = [];
  pageNo: any = 1;
  search: any;
  totalItem: any;
  baseUrl = environment.imageUrl;
  isSellSuccess: boolean = false;
  searchForm: FormGroup;
  length = 10;

  constructor(
    public router: Router,
    private http: HttpService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.searchForm = this.fb.group({
      search: [""],
    });
  }

  ngOnInit() {
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  getSellWebPaginationList(pageNo: any, search: any) {
    this.isSellSuccess = false;
    this.sellData = [];
    let StartPage = pageNo - this.length;
    var param = {
      draw: 2,
      columns: [
        {
          data: "created_by__first_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "created_at",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 1,
          dir: "undefined",
        },
      ],
      start: StartPage,
      length: this.length,
      search: {
        value: this.searchForm.get("search").value,
        regex: false,
      },
    };
    param["isAdmin"] = true;
    this.http
      .httpPostWithHeaderAndBody("getGroupList", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.sellData = res["data"];
          this.totalItem = res.recordsTotal;
          this.isSellSuccess = true;
        } else if (res.code == 204) {
          this.sellData = [];
          this.isSellSuccess = true;
        } else {
          this.isSellSuccess = true;
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  submitSearch() {
    this.pageNo = 1;
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  onKeyUp(event) {
    this.search = event;
    this.pageNo = 1;
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  deleteGroup(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteHeader("deleteGroup", id).subscribe((res: any) => {
          Swal.fire("Deleted!", "Group Chat has been deleted.", "success");
          this.getSellWebPaginationList(
            this.pageNo * this.length,
            this.searchForm.get("search").value
          );
        });
      }
    });
  }
}
