import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-addadvertise',
  templateUrl: './addadvertise.component.html',
  styleUrls: ['./addadvertise.component.scss']
})
export class AddadvertiseComponent implements OnInit {
  routeId: any;
  type: any
  userId: string;
 

  constructor(private route: ActivatedRoute) {
    this.type = this.route.snapshot.paramMap.get('type');
    if(this.type === 'edit') {
      this.userId = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit() {
   
  }

  }
