import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { MustMatch } from "src/app/services/must-match.validators";
import { environment } from "src/environments/environment";
import { CommonService } from 'src/app/services/common.service';
declare var $: any;

@Component({
  selector: "app-addjob",
  templateUrl: "./addjob.component.html",
  styleUrls: ["./addjob.component.scss"],
})
export class AddjobComponent implements OnInit {
  routeId: any;
  type: any;
  userId: string;
  addEditJobForm: FormGroup;
  fileData: File;
  imageUrl: string = "";
  submitted: boolean = false;
  options = {
    types: [],
    componentRestrictions: {
      country: [],
    },
  };
  formattedaddress = " ";
  jobData: any;
  longitude: any;
  latitude: any;
  employMentList: any[] = ["Full-Time", "Regular"];
  fileId: any;
  todayDate: any;
  imgBaseUrl = environment.imageUrl;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private https: HttpService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.type = this.route.snapshot.paramMap.get("type");
    //if (this.type === "edit") {
    this.userId = this.route.snapshot.paramMap.get("id");
    //}
    console.log(this.route.snapshot);
    
  }

  ngOnInit() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    this.todayDate = new Date(mm + "/" + dd + "/" + yyyy);
    this.createForm();
    if (this.type !== "add") {
      this.getBannerData();
    }
  }

  createForm() {
    (this.addEditJobForm = this.fb.group(
      {
        image: [""],
        bankName: [
          "",
          [Validators.required, Validators.pattern(new RegExp("\\S"))],
        ],
        jobNo: ["", [Validators.required, Validators.pattern(/^[0-9]{1,5}$/)]],
        jobTitle: [
          "",
          [Validators.required, Validators.pattern(new RegExp("\\S"))],
        ],
        location: ["", [Validators.required]],
        requestDate: ["", Validators.required],
        expiryDate: ["", Validators.required],
        description: ["", Validators.required],
        employMentType: ["", Validators.required]
      }
    ))
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.fileData = <File>fileInput.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = (event) => {
        this.imageUrl = reader.result as string;
      };
    }
    if (this.fileData) {
      let formData: FormData = new FormData();
      formData.append("media", this.fileData, this.fileData.name);
      this.https
        .httpPostWithFormDataHeaderAndBody("uploadMedia", formData)
        .subscribe((res: any) => {
          if (res.code == 200) {
            this.fileId = res.data[0].id;
          }
        });
    }
  }

  submit() {
    this.submitted = true;
    if (!this.addEditJobForm.valid || this.imageUrl === "") {
      return true;
    }
    if (this.addEditJobForm.valid) {
      var formData = new FormData();
      var datePipe = new DatePipe("en-US");
      let requestDate = datePipe.transform(
        this.addEditJobForm.value.requestDate,
        "MM/dd/yyyy"
      );
      let expiryDate = datePipe.transform(
        this.addEditJobForm.value.expiryDate,
        "MM/dd/yyyy"
      );
      formData.append("image", this.fileId);
      formData.append("bank_name", this.addEditJobForm.value.bankName.trim());
      formData.append("jobs_count", this.addEditJobForm.value.jobNo);
      formData.append("job_title", this.addEditJobForm.value.jobTitle.trim());
      formData.append(
        "employement_type",
        this.addEditJobForm.value.employMentType.trim()
      );
      formData.append("address", this.addEditJobForm.value.location);
      formData.append("longitude", this.longitude);
      formData.append("latitude", this.latitude);
      formData.append("request_date", requestDate);
      formData.append("expiry_date", expiryDate);
      formData.append(
        "description",
        this.addEditJobForm.value.description
      );
      this.type === "add" &&
        this.https
          .httpPostWithFormDataHeaderAndBody("createJobs", formData)
          .subscribe((res: any) => {
            if (res.code == 200) {
              this.commonService.presentsToast("success","Job created successfully");
              this.router.navigateByUrl("/superadmin/jobs");
            }
          });
      this.type === "edit" &&
        this.https
          .httpPutWithFormDataHeaderAndBodyWithid(
            "updateJobs",
            this.userId,
            formData
          )
          .subscribe((res: any) => {
            if (res.code == 200) {
              this.commonService.presentsToast("success","Job updated successfully");
              this.router.navigateByUrl("/superadmin/jobs");
            }
          });
    }
  }

  restrictonView(event) {
    if (this.type === "view") {
      event.stopPropagation();
    }
  }

  onInputChange(event) {
    var locationName: any = "";
    locationName = this.addEditJobForm.value.location;
    if (locationName != this.formattedaddress) {
      this.addEditJobForm.controls["location"].reset();
    }
  }

  AddressChange(address: any) {
    address.address_components.forEach((res) => {
      if (
        res.types.includes("locality") ||
        res.types.includes("postal_town") ||
        res.types.includes("sublocality") ||
        res.types.includes("sublocality_level_1") ||
        res.types.includes("sublocality_level_2") ||
        res.types.includes("sublocality_level_3") ||
        res.types.includes("sublocality_level_4") ||
        res.types.includes("sublocality_level_5")
      ) {
        this.formattedaddress = res.long_name;
      }
    });
    this.longitude = address.geometry.location.lng();
    this.latitude = address.geometry.location.lat();
    this.addEditJobForm.controls["location"].patchValue(this.formattedaddress);
    console.log(this.formattedaddress);
  }

  getBannerData() {
    this.https
      .httpGetWithHeaderAndId("getJobById", this.userId)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.jobData = res.data;          
          this.patchValue(this.jobData);
        }
      });
  }

  patchValue(data) {
    this.addEditJobForm.patchValue({
      bankName: data.bank_name,
      jobNo: data.jobs_count,
      jobTitle: data.job_title,
      location: data.address,
      requestDate: data.request_date,
      expiryDate: data.expiry_date,
      description: data.description,
      employMentType: data.employement_type
    });
    this.imageUrl = `${data.image.media_file_url}`;
    this.longitude = data.longitude;
    this.latitude = data.latitude;
    this.fileId = data.image.id;
  }

  onSelection(event) { }

  onlyNumberKey(evt) {
    // Only ASCII charactar in that range allowed
    const charCode = evt.which ? evt.which : evt.keyCode;
    console.log(charCode)
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
}
