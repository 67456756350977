import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit {
  arr = [];
  allJobList: any[] = [];
  pageNo: any = 1;
  imageUrl: any;
  baseUrl = environment.imageUrl;
  searchValue: any = "";
  search: any;
  totalItem: any;
  showTable: boolean = false;
  searchForm: FormGroup;
  length = 10;

  constructor(
    public router: Router,
    private http: HttpService,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
    this.searchForm = this.fb.group({
      search: [""],
    });
  }

  ngOnInit() {
    this.arr = [
      {
        name: "Texas Regional Bank",
        noJobs: "50",
        title: "Manager",
        location: "Chandigarh",
        description: "Lorem ipsum",
        applied: "14",
        jobsId: "258",
        noReports: "25",
        startDate: "02-02-2021",
        endDate: "02-03-2021",
        days: "30",
      },
    ];
    this.getAllJobs(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  getAllJobs(pageNo: any, search: any) {
    this.allJobList = [];
    let page = pageNo - this.length;
    var param = {
      draw: 2,
      columns: [
        {
          data: "bank_name",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "job_title",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "address",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "id",
          name: "",
          searchable: false,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 3,
          dir: "undefined",
        },
      ],
      start: page,
      length: this.length,
      search: {
        value: this.searchForm.get("search").value,
        regex: false,
      },
    };
    this.http
      .httpPostWithHeaderAndBody("getAllJobs", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.allJobList = res.data;
          this.totalItem = res.recordsTotal;
          this.showTable = true;
        } else {
          this.showTable = true;
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getAllJobs(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  submitSearch() {
    if (this.searchForm.get("search").value !== "") {
      this.pageNo = 1;
      this.getAllJobs(
        this.pageNo * this.length,
        this.searchForm.get("search").value
      );
    }
  }

  onKeyUp(event) {
    this.search = event;
    this.pageNo = 1;
    this.getAllJobs(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  deleteJob(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteHeader("deleteJob", id).subscribe((res: any) => {
          Swal.fire("Deleted!", "Banner has been deleted.", "success");
          this.getAllJobs(
            this.pageNo * this.length,
            this.searchForm.get("search").value
          );
        });
      }
    });
  }

  toggle(event, number) {
    console.log(event);

    var param;
    if (event.checked == false) {
      param = {
        job_id: number,
        job_status: 0,
      };
    } else {
      param = {
        job_id: number,
        job_status: 1,
      };
    }
    this.http
      .httpPutWithDataHeaderAndBody("changeJobStatus", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.commonService.presentsToast(
            "success",
            "Status Changed successfully"
          );
        }
      });
  }
}
