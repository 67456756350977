import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {
  imageFile1 :any
  docImage1:any
  isUpdatedImage: boolean;
  public submitted: boolean = false;
  newImage1: any;
  csvForm:FormGroup
  constructor(private dialogRef: MatDialogRef<AddLeadComponent>,private commonservice:CommonService, private fb: FormBuilder,private http:HttpService) {
    this.csvForm = this.fb.group({
      img:[]
      })
  }

  ngOnInit(): void {
  }

  uploadCsv(event) {
    const allowedTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    const file = event.target.files[0];
    const fileType = file.type;

    if (!allowedTypes.includes(fileType)) {
      this.commonservice.presentsToast('error',"Please select a CSV, XLSX, or XLS file.");
      // Clear the file input
      event.target.value = null;
      return;
    }
    console.log(event)
    this.isUpdatedImage = true;
    this.docImage1 = event.target.files[0];
    console.log(this.docImage1);
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.newImage1 = event.target.result;
        console.log(this.newImage1,'nnn')
      };
      
    }
  }
  
  submitImportFile() {
    this.submitted = true;
    if (this.submitted && this.docImage1) {
      const fd = new FormData();
      fd.append("csv_file", this.docImage1, this.docImage1.name); // Append the file itself
  
      this.http
        .httpPostWithFormDataHeaderAndBody('csvapi', fd)
        .subscribe(
          (response: any) => {
            if (response.code == 200) {
              this.dialogRef.close();
              this.commonservice.presentsToast('success', "Csv added successfully");
              this.submitted = false;
              this.commonservice.uploadcsv$.next(true)
            }
          },
          (error) => {
            this.commonservice.presentsToast('error', error);
          }
        );
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }



}
