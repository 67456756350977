import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-viewuser',
  templateUrl: './viewuser.component.html',
  styleUrls: ['./viewuser.component.scss']
})
export class ViewuserComponent implements OnInit {
  routeId: any;
  type: any
  userId: string;
  profile_data: any;
  education_data: any;
  work_data: any;

  constructor(private route: ActivatedRoute, private http: HttpService) {
    this.userId = this.route.snapshot.paramMap.get('id');      
  }

  ngOnInit() {
   this.getProfileDetails();
   this.getEducationalDetails();
   this.getWorkExpDetails();
  }

  getProfileDetails(){ 
    this.http.httpGetWithHeaderAndId("getProfileDetailsByUserId",this.userId).subscribe((res:any)=>{
      if(res.code == 200)
      {
        console.log(res.data);
        this.profile_data = res.data;
      }
    })
  }

  getEducationalDetails(){
    this.http.httpGetWithHeaderAndId("getAllUserEducationDetailsByUserId",this.userId).subscribe((res:any)=>{
      if(res.code == 200)
      {
        console.log(res.data);
        this.education_data = res.data[0];
      }
    })
  }

  getWorkExpDetails(){
    this.http.httpGetWithHeaderAndId("getAllUserWorkExperienceDetailsByUserId",this.userId).subscribe((res:any)=>{
      if(res.code == 200)
      {
        console.log(res.data);
        this.work_data = res.data[0];
      }
    })
  }
}
