import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberCommaDirective } from '../services/directive/number-comma.directive';
import { CharacterOnlyDirective } from '../services/directive/character-only.directive';
import { NoZeroDirective } from '../services/directive/no-zero.directive';
import { TwoDecimalPointDirective } from '../services/directive/two-decimal-point.directive';
import { appNoSpaceAtFirstDirective } from '../services/directive/no-space.directive';



@NgModule({
  declarations: [NumberCommaDirective,CharacterOnlyDirective,NoZeroDirective,TwoDecimalPointDirective,appNoSpaceAtFirstDirective],
  imports: [
    CommonModule
  ],
  exports: [
    NumberCommaDirective,
    CharacterOnlyDirective,
    NoZeroDirective,
    TwoDecimalPointDirective,
    appNoSpaceAtFirstDirective
  ],
})
export class SharedModule { }
