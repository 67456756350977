import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    const token = localStorage.Authorization;
    const role = localStorage.roleId;

    if (next.routeConfig.path === "login") {
      if ((token !== null && role== 1)|| (token !== null && role == 5)) {
        this.router.navigateByUrl("/superadmin/dashboard");
        return false;
      } else if (
        token !== null && role == 2
      ) {
        this.router.navigateByUrl("/subadmin/dashboardSubadmin");
        return false;
      } else {
        return true;
      }
    } else {
      if (token !== null && token !== undefined && token !== '') {
        return true;
      } else {
        localStorage.clear();
        this.router.navigateByUrl("/auth/login");
        return false;
      }
    }
  }
}
