import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError, of } from "rxjs";
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  retry,
  tap,
} from "rxjs/operators";
import { CommonService } from "../services/common.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private commonService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService
  ) {
    if (
      localStorage.accessToken != undefined ||
      localStorage.accessToken != null
    ) {
      this.token = localStorage.accessToken;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.start();
    return next.handle(req).pipe(
      // retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          this.router.navigateByUrl("/dashboard");
        }
        if (error.status == 401) {
        }
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.stop();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes.body) {
            if (eventRes.body.statusCode === 400 || eventRes.body.code == 400) {
              if (eventRes.body.error) {
                this.commonService.openDialog("error", eventRes.body.error);
              } else if (eventRes.body.message) {
                this.commonService.openDialog("error", eventRes.body.message);
              }
            }
          }
        },
        (error) => {
          switch (error.code) {
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.commonService.presentsToast(
                "warning",
                "You have been loggedOut for security purpose."
              );
              this.router.navigate([`/login`]);
              localStorage.clear();
              break;
            case 500:
              /*** If api does not respond  ** */
              this.commonService.presentsToast("warning", "Api Not Working");
              break;

            default:
              if (error.error) {
                this.commonService.openDialog("error", error.error.message);
              } else {
                this.commonService.openDialog("error", error.message);
              }
          }
        }
      )
    );
  }
}
