import { Component, OnInit, Input, } from '@angular/core';
import { EChartsOption } from 'echarts';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-my-chart',
  templateUrl: './my-chart.component.html',
  styleUrls: ['./my-chart.component.scss']
})
export class MyChartComponent implements OnInit {

  @Input() chartData: any;
  chartOption:EChartsOption;
  dates: any[]=[];
  counts: any[]=[];
  datePipe = new DatePipe("en-US");

  constructor() { }

  ngOnInit() {
    
    if(this.chartData) {
      for (let element of this.chartData) {
        this.dates.push(element.date);
        this.counts.push(element.count);        
     }
    }
    this.setOptions();
  }

  setOptions() {
    this.chartOption = {
      xAxis: {
        type: 'category',
        data: this.dates,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: this.counts,
          type: 'line',
        },
      ],
      tooltip: {
        axisPointer: {
          type: 'shadow'
        }
      },
      color: ['#5890C5']
    };
  }

}
