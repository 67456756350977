import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { extend, isNullOrUndefined, Browser } from '@syncfusion/ej2-base';
import {
    ScheduleComponent, ActionEventArgs, PopupOpenEventArgs, EventRenderedArgs, RenderCellEventArgs, DragAndDropService,
    TimelineViewsService, GroupModel, EventSettingsModel, ResizeService, TimeScaleModel, WorkHoursModel, View
} from '@syncfusion/ej2-angular-schedule';
import { roomData } from '../../../../data';
import { DatePipe } from '@angular/common'
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-calender',
    templateUrl: 'calender.component.html',
    styleUrls: ['calender.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [TimelineViewsService, ResizeService, DragAndDropService]
})

export class CalenderComponent {   

    datePipe = new DatePipe("en-US");
    calendarDeatils: any[];
    showDetails: boolean = false;

    public selectedDate: Date = new Date();
    public timeScale: TimeScaleModel = { interval: 60, slotCount: 1 };
    public workHours: WorkHoursModel = { start: '08:00', end: '18:00' };
    public currentView: View = 'TimelineWeek';

    public allowMultiple: Boolean = true;
    public eventSettings: EventSettingsModel = {
        fields: {
            id: 'Id',
            subject: { title: 'Summary', name: 'Subject' },
            location: { title: 'Location', name: 'Location' },
            description: { title: 'Comments', name: 'Description' },
            startTime: { title: 'From', name: 'StartTime' },
            endTime: { title: 'To', name: 'EndTime' },
        }
    };

    @ViewChild('scheduleObj',{static: true})
    public scheduleObj: ScheduleComponent;

    constructor(private http:HttpService) {}

    ngOnInit() {
        this.getAllEevents();
    }
    
    getAllEevents() {
        this.http.httpGetWithHeaderOnly("getAllEevents").subscribe((res:any)=>{
            if(res.code == 200) {
                this.calendarDeatils = res.data;     
                this.showDetails = true;
                for(var x=0; x < this.calendarDeatils.length; x++) {
                    this.calendarDeatils[x].EndTime = new Date(this.calendarDeatils[x].EndTime)
                    this.calendarDeatils[x].StartTime = new Date(this.calendarDeatils[x].StartTime)                   
                }  
                this.eventSettings = {
                    dataSource: <Object[]>extend([], this.calendarDeatils, null, true),
                }        
            }
        })
    }

    addEvents(data) {
        let param = {   
            Description: data.Description == undefined ? null : data.Description,        
            EndTime: this.datePipe.transform(data.EndTime, 'MMM d, y, h:mm:ss a'),
            EndTimezone: data.EndTimezone,
            IsAllDay: data.IsAllDay,
            Location: data.Location == undefined ? "" : data.Location,
            RecurrenceRule: data.RecurrenceRule,
            StartTime: this.datePipe.transform(data.StartTime, 'MMM d, y, h:mm:ss a'),
            StartTimezone: data.StartTimezone,     
            Subject: data.Subject,            
        }       
        this.http.httpPostWithHeaderAndBody("addEvent", param).subscribe((res:any)=>{
            if(res.code == 200) {
                this.getAllEevents();        
            }
        })
    }

    updateEvents(data) {
        let param = {   
            Description: data.Description == undefined ? null : data.Description,        
            EndTime: this.datePipe.transform(data.EndTime, 'MMM d, y, h:mm:ss a'),
            EndTimezone: data.EndTimezone,
            IsAllDay: data.IsAllDay,
            Location: data.Location == undefined ? "" : data.Location,
            RecurrenceRule: data.RecurrenceRule,
            StartTime: this.datePipe.transform(data.StartTime, 'MMM d, y, h:mm:ss a'),
            StartTimezone: data.StartTimezone,     
            Subject: data.Subject,            
        }   
        this.http.httpPutWithDataHeaderAndBodyWithid("updateEvent", data.id, param).subscribe((res:any)=>{
            if(res.code == 200) {
                this.getAllEevents();        
            }
        })
    }

    deleteEvent(Id) {   
        this.http.httpDeleteHeader("deleteEvent", Id).subscribe((res:any)=>{
            if(res.code == 200) {
                this.getAllEevents();        
            }
        })
    }

    isReadOnly(endDate: Date): boolean {        
        return (endDate < new Date());
    }

    onPopupOpen(args: PopupOpenEventArgs): void {
        let data: { [key: string]: Object } = <{ [key: string]: Object }>args.data;
        if (args.type === 'QuickInfo' || args.type === 'Editor' || args.type === 'RecurrenceAlert' || args.type === 'DeleteAlert') {
            let target: HTMLElement = (args.type === 'RecurrenceAlert' ||
                args.type === 'DeleteAlert') ? args.element[0] : args.target;
            if (!isNullOrUndefined(target) && target.classList.contains('e-work-cells')) {
                if ((target.classList.contains('e-read-only-cells')) ||
                    (!this.scheduleObj.isSlotAvailable(data))) {
                    args.cancel = true;
                }
            } else if (!isNullOrUndefined(target) && target.classList.contains('e-appointment') &&
                (this.isReadOnly(data.EndTime as Date))) {
                args.cancel = true;
            }
        }
    }

    onActionBegin(args: ActionEventArgs): void {
        if (args.requestType === 'eventCreate' || args.requestType === 'eventChange' || args.requestType === 'eventRemove') {
            let data: { [key: string]: Object };
            if (args.requestType === 'eventCreate') {
                data = <{ [key: string]: Object }>args.data[0];
                this.addEvents(data);
            } else if (args.requestType === 'eventChange') {
                let myData;
                data = <{ [key: string]: Object }>args.data;
                if(args.changedRecords.length != 0) {
                    myData = args.changedRecords[0];              
                } else {
                    myData = data[0];
                }  
                this.updateEvents(myData);
            } else if (args.requestType === 'eventRemove') {
                let deleteId;
                data = <{ [key: string]: Object }>args.data;
                if(args.changedRecords.length != 0) {                    
                    deleteId = args.changedRecords[0]['id'];                
                } else {
                    deleteId = data[0]['id'];
                }         
                this.deleteEvent(deleteId)
            }
            if (!this.scheduleObj.isSlotAvailable(data)) {
                args.cancel = true;
            }
        }
    }

    onRenderCell(args: RenderCellEventArgs): void {
        if (args.element.classList.contains('e-work-cells')) {
            if (args.date < new Date(2018, 6, 31, 0, 0)) {
                args.element.setAttribute('aria-readonly', 'true');
                args.element.classList.add('e-read-only-cells');
            }
        }
        if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
            let target: HTMLElement = args.element.querySelector('.e-resource-text') as HTMLElement;
        }
    }

    onEventRendered(args: EventRenderedArgs): void {
        let data: { [key: string]: Object } = args.data;
        if (this.isReadOnly(data.EndTime as Date)) {
            args.element.setAttribute('aria-readonly', 'true');
            args.element.classList.add('e-read-only');
        }
    }
}
