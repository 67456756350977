import { Input, Directive, ElementRef, HostListener} from '@angular/core';


@Directive({
  selector:'[NoZero]'
})


export class NoZeroDirective {
  @Input() isAlphaNumeric!: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(): void {
    const initalValue = this.el.nativeElement.value;
    if (initalValue.length === 1 && initalValue[0] === '0') {
      this.el.nativeElement.value = '';
      
    }
  }
}