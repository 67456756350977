import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { CommonService } from "../../../../services/common.service";

@Component({
  selector: 'app-add-access',
  templateUrl: './add-access.component.html',
  styleUrls: ['./add-access.component.scss']
})
export class AddAccessComponent implements OnInit {

  type: any;
  userId: string;
  addEditAccessForm: FormGroup;
  accessData: any;
  submitted: boolean = false;
  userPermissionList: any;
  addAccessForm: FormGroup;
  role_id = 5;
  imageToUpload: File = null;
  imageData: any;
  value1 = 1;
  value2 = 0;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private https: HttpService,
    private router: Router,
    private commonService: CommonService,
  ) {
    this.type = this.route.snapshot.paramMap.get("type");
    this.userId = this.route.snapshot.paramMap.get("id");

    this.addAccessForm = this.fb.group({
      first_name: new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]),
      last_name: new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]),
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      phone_no: new FormControl("",[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      is_user: [false],
      is_participants: [false],
      is_jobs: [false],
      is_advertise: [false],
      is_cms: [false],
      is_role: [false],
      is_analytics: [false],
   })
  }

  ngOnInit() {
    this.getAllUsersForPermission();
  }

  getAllUsersForPermission() {
    this.https.httpGetWithHeaderOnly("getAllUsersForPermission")
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.userPermissionList = res.data;
          console.log(this.userPermissionList);
          
        }
      });
  }

  handleFileInput(files: FileList) {
    this.imageToUpload = files.item(0);
    console.log(this.imageToUpload);
    var reader = new FileReader();
    reader.readAsDataURL(files.item(0));
    reader.onload = (event) => {
    this.imageData = reader.result}
  }

  submit() {
    this.submitted = true;
    if (!this.addAccessForm.valid) {
      return true;
    }
    if (this.addAccessForm.valid) {
      console.log(this.addAccessForm.value)
      let formdata = new FormData();
      formdata.append("profile_image", this.imageToUpload);
      formdata.append("first_name",this.addAccessForm.controls["first_name"].value);
      formdata.append("last_name",this.addAccessForm.controls["last_name"].value);
      formdata.append("phone_no",this.addAccessForm.controls["phone_no"].value);
      formdata.append("email",this.addAccessForm.controls["email"].value);
      formdata.append("password",this.addAccessForm.controls["password"].value);
      formdata.append("role",JSON.stringify(this.role_id));
      if(this.addAccessForm.value.is_user == true){
        formdata.append("is_user",JSON.stringify(this.value1));
      }
      else{
        formdata.append("is_user",JSON.stringify(this.value2));
      }
      if(this.addAccessForm.value.is_participants == true){
        formdata.append("is_participants",JSON.stringify(this.value1));
      }
      else{
        formdata.append("is_participants",JSON.stringify(this.value2));
      }
      if(this.addAccessForm.value.is_jobs == true){
        formdata.append("is_jobs",JSON.stringify(this.value1));
      }
      else{
        formdata.append("is_jobs",JSON.stringify(this.value2));
      }
      if(this.addAccessForm.value.is_advertise == true){
        formdata.append("is_advertise",JSON.stringify(this.value1));
      }
      else{
        formdata.append("is_advertise",JSON.stringify(this.value2));
      }
      if(this.addAccessForm.value.is_cms == true){
        formdata.append("is_cms",JSON.stringify(this.value1));
      }
      else{
        formdata.append("is_cms",JSON.stringify(this.value2));
      }
      if(this.addAccessForm.value.is_role == true){
        formdata.append("is_role",JSON.stringify(this.value1));
      }
      else{
        formdata.append("is_role",JSON.stringify(this.value2));
      }
      if(this.addAccessForm.value.is_analytics == true){
        formdata.append("is_analytics",JSON.stringify(this.value1));
      }
      else{
        formdata.append("is_analytics",JSON.stringify(this.value2));
      }
      
      this.https.httpPostWithFormDataHeaderAndBody("addSubAdmin", formdata)
        .subscribe((res: any) => {
          if (res.code == 200) {
            this.commonService.presentsToast('success', "Roles/Access is added successfully");
            this.router.navigateByUrl("/superadmin/access");
          }
        });
    }
  }

}
