import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-reach-loan",
  templateUrl: "./reach-loan.component.html",
  styleUrls: ["./reach-loan.component.scss"],
})
export class ReachLoanComponent implements OnInit {
  Id: string;
  detailsData: any;
  detailForm: FormGroup;
  type: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private https: HttpService,
    private fb: FormBuilder
  ) {
    this.Id = this.route.snapshot.paramMap.get("id");
    this.route.queryParamMap.subscribe((res: any) => {
      this.type = res.params.type;
      console.log(res);
    });
  }

  ngOnInit() {
    this.detailForm = this.fb.group({
      loan_type: [""],
      loan_performing: [""],
      loan_collateral_type: [""],
      address: [""],
      amount: [""],
      description: [""],
    });
    this.getData();
  }

  getData() {
    this.https
      .httpGetWithHeaderAndId("getParticipantsDetailsById", this.Id)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.detailsData = res.data;
          this.detailForm.patchValue({
            loan_type: this.detailsData["loan_type"].name,
            loan_performing: this.detailsData["loan_performing"].name,
            amount: this.detailsData["amount"],
            address: this.detailsData["address"],
            description: this.detailsData["description"],
            loan_collateral_type: this.detailsData["loan_collateral_type"].name,
          });
        }
      });
  }

  goBack() {
    history.back();
  }
}
