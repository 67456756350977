import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddLeadComponent } from './add-lead/add-lead.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pre-lead',
  templateUrl: './pre-lead.component.html',
  styleUrls: ['./pre-lead.component.scss']
})
export class PreLeadComponent implements OnInit {


  sellData = [];
  pageNo: any = 1;
  search: any;
  totalItem: any;
  isSellSuccess: boolean = false;
  searchForm: FormGroup;
  length = 10;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private http: HttpService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private commonService:CommonService
  ) {
    this.searchForm = this.fb.group({
      search: [""],
    });
  }

  ngOnInit() {
    this.commonService.uploadcsv$.subscribe(res=>{
      if(res){
        this.getSellWebPaginationList(this.pageNo * this.length,this.searchForm.get("search").value);}  
    })
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  getSellWebPaginationList(pageNo: any, search: any) {
    this.isSellSuccess = false;
    this.sellData = [];
    let StartPage = pageNo - this.length;
    var param = {
      "start":StartPage,
      "length": this.length,
      "search": {
      "value": this.searchForm.get("search").value,
      "regex": false
      }
    }
    this.http
    .httpPostWithHeaderAndBody("getUploadCsvList", param)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.sellData = res["data"];
          this.totalItem = res.recordsFiltered;
          this.isSellSuccess = true;
        } else if (res.code == 204) {
          this.sellData = [];
          this.isSellSuccess = true;
        } else {
          this.isSellSuccess = true;
        }
      });
  }

  getPage(pageNo: number) {
    this.pageNo = pageNo;
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  submitSearch() {
    this.pageNo = 1;
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  onKeyUp(event) {
    this.search = event;
    this.pageNo = 1;
    this.getSellWebPaginationList(
      this.pageNo * this.length,
      this.searchForm.get("search").value
    );
  }

  addLead(): void {
    const dialogRef = this.dialog.open(AddLeadComponent, {
      panelClass : ['add_lead_pop']
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  deleteLead(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http
          .httpDeleteHeader("deleteLeadbyId", id)
          .subscribe((res: any) => {
            Swal.fire("Deleted!", "Lead has been deleted.", "success");
            this.getSellWebPaginationList(
              this.pageNo * this.length,
              this.searchForm.get("search").value
            );
          });
      }
    });
  }

}
